class DealModel {
  readonly id: number;
  readonly creatorId: number;
  readonly parcelId: number;
  readonly tripId: number;
  readonly price: number;
  readonly status: string;
  readonly parcel: any;
  readonly trip: any;
  readonly fio: string;
  constructor({
    id,
    creatorId,
    parcelId,
    tripId,
    price,
    status,
    parcel,
    trip,
    fio
  }) {
    this.id = id;
    this.creatorId = creatorId;
    this.parcelId = parcelId;
    this.tripId = tripId;
    this.price = price;
    this.status = status;
    this.parcel = parcel;
    this.trip = trip;
    this.fio = fio;
  }
}

export default DealModel;
