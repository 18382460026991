/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {  useParams ,useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import { COLUMN, OPTIONS, createCarDetail, } from './utils';
import { CarsClient, PlaceDetails, UserClient } from '../../services/api';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Tab, Tabs } from '@material-ui/core';
import { ShifterImages } from 'components/ParcelDetail/ShifterImages';
import UserTripList from 'components/UserComponent/UserTripList/UserTripList';
import { createSortedTripListFromRowData } from 'models/modelFactory/trip';
import { AppContextType, useApp } from 'context/AppContext';
import { getVerificationStatus } from 'models/util';


const CarDetail = function (): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { getCarById, deleteCarById, getTripByCarId} = CarsClient();
  const { getPlaceDetails } = PlaceDetails();
  const { getUserById } = UserClient();
  const [tripList, setTripList] = useState([]);
  const title  = t('detail_car');
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [tabValue, setTabValue] = React.useState('1');
  const [carPhotos, setCarPhotos] = useState([]);
  const [passportPhotos, setPassportPhotos] = useState([]);
  const [carDetails, setCarDetails] = useState({});
  const { baggages } = useApp() as AppContextType;

  const handleChange = (event: any, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const detail = await getCarById(id);
      const userDetail = await getUserById(detail.userId);
      detail.verificationStatus = t(getVerificationStatus(detail.verificationStatus));
      detail.fio = `${userDetail?.firstName ?? ''} ${userDetail?.lastName ?? ''} ${userDetail?.middleName ?? ''}`.trim();
      const list = await getTripByCarId(id);
      const parsedData: any = list
        ? createSortedTripListFromRowData(list, baggages)
        : [];
      const locationFromData = await Promise.all(parsedData.map(trip => getPlaceDetails(trip.locationFromPlaceId)));
      const locationToData = await Promise.all(parsedData.map(trip => getPlaceDetails(trip.locationToPlaceId)));
      const localizedData = parsedData.map(trip => {
        const locationFrom = locationFromData.find(item => item.placeId === trip.locationFromPlaceId);
        const locationTo = locationToData.find(item => item.placeId === trip.locationToPlaceId);
        return {
          ...trip,
          addressFrom: locationFrom.name,
          addressTo: locationTo.name,
          transport: t((trip.transport).toLowerCase())
        }
      });
      setData(createCarDetail(detail,t));
      setCarDetails(detail);
      setCarPhotos(detail.carPhotos ?? []);
      setPassportPhotos(detail.carPassportPhotos ?? []);
      setTripList(localizedData);
    })();
  }, [t,id,baggages]);


  const deleteData = async () => {
    await deleteCarById(id);
    history.goBack();
  }  
  return (
    <React.Fragment>
      <h1 style={{ textAlign: 'center' }}>{title}</h1>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ width: '40%', marginRight: '2%' }}>
          <MUIDataTable
            title={''}
            data={data}
            columns={COLUMN(t, carDetails)}
            options={OPTIONS(t)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={deleteData}>
              {t('delete')}
            </Button>
          </div>
          <div style={{ margin: '5px' }}>
            
          </div>
  
        </div>
        <div style={{ width: '100%', }}>
        <TabContext value={tabValue}>
          <Tabs value={tabValue} onChange={handleChange} textColor='primary' indicatorColor='primary'>
            <Tab value='1' label={`${t('car_photo')} (${carPhotos.length})`} />
            <Tab value='2' label={`${t('photo_inspection')} (${passportPhotos.length})`} />
            <Tab value='3' label={`${t('trips')} (${tripList.length})`} />
          </Tabs>
          <TabPanel value='1'>
            <ShifterImages list={carPhotos} />
          </TabPanel>
          <TabPanel value='2'>
            <ShifterImages list={passportPhotos} />
          </TabPanel>
          <TabPanel value='3'>
            <UserTripList tripList={tripList} />
          </TabPanel>
        </TabContext>
      </div>

    </React.Fragment>
  );
};

export default CarDetail;
