/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect,  useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { GET_COLUMN, OPTIONS,  } from './utils';
import { BlackList } from '../../services/api';
import useQuery from '../../hooks/useQuery';
import createBlackListFromRowData from 'models/modelFactory/blackList';

const BlackListScreen = (): JSX.Element => {
  const {t} = useTranslation();
  const TITLE=t("black_list")
  const [data, setData] = useState<any>([]);
  const { getBlackList } = BlackList();
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const deals = await getBlackList();
    setData(createBlackListFromRowData(deals));
    })();
  }, []);

  const onChangePage = (currentPage: number) => {
    query.set('page', (currentPage + 1).toString());
    window.history.pushState(null, '', `?${query.toString()}`);
  };

  const queryObject: any = query.getObject();

  const checkPageSize = (object) => {
    return object?.pageSize;
  };
  const column = useMemo(() => GET_COLUMN(queryObject,t), []);
  const pageSize = useMemo(() => checkPageSize(queryObject), []);

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={TITLE}
            data={data}
            columns={[...column]}
            options={{
              ...OPTIONS(t),
              onChangeRowsPerPage: (numberOfRows) => {
                query.set('pageSize', numberOfRows.toString());
                window.history.pushState(null, '', `?${query.toString()}`);
              },
              onFilterChange(changedColumn: string, filterList, type, changedColumnIndex) {
                switch (changedColumn) {
                  case 'createdOn': {
                    query.delete('createdOnStart');
                    query.delete('createdOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('createdOnStart', filterList[changedColumnIndex][0]);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('createdOnEnd', filterList[changedColumnIndex][1]);
                    }
                    break;
                  }

                  default:
                    query.delete(changedColumn);
                    if (filterList[changedColumnIndex][0]) {
                      query.set(changedColumn, filterList[changedColumnIndex][0]);
                    }
                    break;
                }
                window.history.pushState(null, '', `?${query.toString()}`);
              },
              rowsPerPage: pageSize || 10,
              setRowProps: (row) => {
                return {
                  onDoubleClick: () => {
                    history.push(`/blackList/details/${row[0]}`);
                  },
                };
              },
              page: parseInt(queryObject.page) || 0,
              onChangePage,
              pagination: data.length > 10
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlackListScreen;
