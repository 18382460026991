/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import * as core from '@material-ui/core';
import * as style from '@material-ui/core/styles';

import { NAV_ITEMS, NAV_DEFAULT_ITEMS } from './utils';
import NavItem from './NavItem';

const drawerWidth = 240;

const useStyles = style.makeStyles((theme: style.Theme) =>
  style.createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  })
);

interface SidebarComponentProps {
  container?: Element;
}

export function SidebarComponent(props: SidebarComponentProps): JSX.Element {
  const {t}=useTranslation();
  const classes = useStyles();

  function exit() {
    localStorage.clear();
    window.location.replace('/login');
  }
  const onClick = (to) => {
    switch (to) {
      case '/parcel':
        localStorage.setItem('parcelPaginationIndex', `0`);
        break;
      case '/user':
        localStorage.setItem('userPaginationIndex', `0`);
        break;
      case '/trip':
        localStorage.setItem('dealPaginationIndex', `0`);
        break;
      default:
    }
  };
  const drawer = (
    <div>
      <Link
        onClick={exit}
        to='/'
        style={{
          fontSize: 18,
          textAlign: 'center',
          display: 'block',
          marginTop: 20
        }}>
        {t("sign_out")}
      </Link>
      <core.Divider style={{ marginTop: 20 }} />
      <core.List>
        {NAV_ITEMS(t).map(({ to, key, name, navIcon, linkType },index:number) => (
          <NavItem
            to={to}
            key={index}
            name={name}
            navIcon={navIcon}
            linkType={linkType}
            onClick={() => onClick(to)}
          />
        ))}
        <core.Divider />
        {NAV_DEFAULT_ITEMS.map(({ to,  name, navIcon, linkType },index:number) => (
          <NavItem to={to} key={index} name={name} navIcon={navIcon} linkType={linkType} />
        ))}
      </core.List>
    </div>
  );

  return (
    <div className={classes.root}>
      <core.CssBaseline />
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* <core.Hidden smUp implementation="css">
          <core.Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}>
            {drawer}
          </core.Drawer>
        </core.Hidden> */}
        {/* <core.Hidden xsDown implementation="css"> */}
        <core.Drawer classes={{ paper: classes.drawerPaper }} variant='permanent' open>
          {drawer}
        </core.Drawer>
        {/* </core.Hidden> */}
      </nav>
    </div>
  );
}
