import moment from 'moment';
import { FormGroup, FormLabel, IconButton, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { UserClient } from '../../services/api';

const { removeUserById } = UserClient();

export const getUserTypeByCode = (code: string) => {
  switch (code) {
    case '1': return 'shifter';
    case '2': return 'sender';
    default: return 'shiftersender';
  }
}

export const getDeviceType = (type: string): string => {
  switch (type) {
    case 'Ios': return 'iOS'
    case 'Web': return 'Web'
    default: return 'Android'
  }
}

export const GET_COLUMN = (filtersFromUrl: any, t: any) => [
  {
    name: 'phone',
    label: t('phone_number'),
    options: {
      filter: true,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.userName ? [filtersFromUrl.userName] : []

    }
  },
  {
    name: 'role',
    label: t('role'),
    options:{
      filterList: filtersFromUrl.role ? [filtersFromUrl.role] : [],
      filterOptions: {
        names: [t('shiftersender'), t('shifter'), t('sender')]
      },
      customFilterListOptions: {
        render: (v: string) => {
          return v
        }
      }
    }
  },
  {
    name: 'id',
    label: 'id',
    options: {
      "display": false,
      "viewColumns": false,
      filter: false
    }
  },
  {
    name: 'lastName',
    label: t('second_name'),
    options: {
      filter: true,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.secondName ? [filtersFromUrl.secondName] : []

    }
  },
  {
    name: 'firstName',
    label: t('first_name_user'),
    options: {
      filter: true,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.firstName ? [filtersFromUrl.firstName] : []

    }
  },
  {
    name: 'middleName',
    label: t('third_name'),
    options: {
      filter: true,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.thirdName ? [filtersFromUrl.thirdName] : []

    }
  },
  {
    name: 'birthday',
    label: t('birth_day'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t("from")}: ${v[0]}`, `${t("to")}: ${v[1]}`];
          } else if (v[0]) {
            return `${t("from")}: ${v[0]}`;
          } else if (v[1]) {
            return `${t("to")}: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.birthdayOnStart) {
          list[0] = filtersFromUrl.birthdayOnStart;
        }
        if (filtersFromUrl.birthdayOnEnd) {
          list[1] = filtersFromUrl.birthdayOnEnd;
        }
        return list;
      })(),
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput; }, obj2: { data: moment.MomentInput; }) => {
          const val1 = moment(obj1.data, "DD.MM.YYYY HH:mm");
          const val2 = moment(obj2.data, "DD.MM.YYYY HH:mm");
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      //sortDirection: 'desc',
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, "DD.MM.YYYY HH:mm");
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (filterList: { [x: string]: any; }, onChange: (arg0: any, arg1: any, arg2: any) => void, index: string | number, column: any): JSX.Element => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t("birth_day")}</FormLabel>
            <FormGroup row>
              <TextField
                id="startDate"
                label={t("start_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id="endDate"
                label={t("end_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'email',
    label: t('email'),
    options: {
      filter: true,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.email ? [filtersFromUrl.email] : []

    }
  },
  {
    name: 'createdOn',
    label: t('created_on'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t("from")}: ${v[0]}`, `${t("to")}: ${v[1]}`];
          } else if (v[0]) {
            return `${t("from")}: ${v[0]}`;
          } else if (v[1]) {
            return `${t("to")}: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.createdOnStart) {
          list[0] = filtersFromUrl.createdOnStart;
        }
        if (filtersFromUrl.createdOnEnd) {
          list[1] = filtersFromUrl.createdOnEnd;
        }
        return list;
      })(),
      //sortDirection: 'desc',
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput; }, obj2: { data: moment.MomentInput; }) => {
          const val1 = moment(obj1.data, "DD.MM.YYYY HH:mm");
          const val2 = moment(obj2.data, "DD.MM.YYYY HH:mm");
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, "DD.MM.YYYY HH:mm");
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (filterList: { [x: string]: any; }, onChange: (arg0: any, arg1: any, arg2: any) => void, index: string | number, column: any): JSX.Element => (
          <div>
            <FormLabel>{t("date_of_creation")}</FormLabel>
            <FormGroup row>
              <TextField
                id="startDate"
                label={t("start_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '48%', marginRight: '4%', marginTop: '3%' }}
              />
              <TextField
                id="endDate"
                label={t("end_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '48%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'deviceType',
    label: t('devType'),
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'id',
    label: t('delete'),
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRender: (value) => (
        <IconButton
          onClick={async () => {
            const answer = window.confirm(t('user_remove_not'));
            if (answer) {
              const removeUser = await removeUserById(value);
              debugger
              if (removeUser) {
                removeUser.Error ? alert(removeUser.Error.Message) : window.location.reload();
              }
            }
          }}>
          <Delete />
        </IconButton>
      )
    }
  }
];

export const OPTIONS: any = (t: any) => {
  return {
    print: false,
    download: true,
    filter: true,
    search: false,
    sort: true,
    viewColumns: false,
    selectableRows: 'none',
    downloadOptions: {
      filename: "excel-format.csv",
      separator: ";",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t("sort"),
        columnHeaderTooltip: (column: any) => `${t("sort_by")} ${column.label}`,
      },
      pagination: {
        next: t("next_page"),
        previous: t("previous"),
        rowsPerPage: t("rowsPerPage"),
        displayRows: t("a_from"),
      },
      toolbar: {
        search: t("search"),
        downloadCsv: t("downloadCsv"),
        print: t("print"),
        viewColumns: t("viewColumn"),
        filterTable: t("filterTable"),
      },
      filter: {
        all: t("all"),
        title: t('filters'),
        reset: t("reset"),
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t("show_hide_table_columns"),
      },
      selectedRows: {
        text: t("row_selected"),
        delete: t("delete"),
        deleteAria: t("delete_area"),
      },
    },
  }
};
