/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import * as core from '@material-ui/core';
import * as style from '@material-ui/core/styles';
import * as icon from '@material-ui/icons';
import moment from "moment-timezone";
import { Language } from "./Language";
import { TimeZone } from './TimeZone';
import { Currency } from './Currency';

const drawerWidth = 240;

const useStyles = style.makeStyles((theme: style.Theme) =>
  style.createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  })
);

export function Header(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const tz = moment.tz.guess();
  const timeZone = localStorage.getItem('timeZone')??tz;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(()=>{
    moment.tz.setDefault(timeZone);

  },[timeZone])

  const titleText = '';

  return (
    <div style={{ marginBottom: '70px' }}>
      <core.AppBar position="fixed" className={classes.appBar}>
        <core.Toolbar>
          <core.IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <icon.Menu />
          </core.IconButton>
          <Language />
          <TimeZone/>
          <core.Typography
            variant="h6"
            noWrap
            style={{ paddingLeft: -30, margin: 'auto', paddingRight: 44 }}>
            {titleText ? `${titleText}` : t('control_panel')}
          </core.Typography>
        </core.Toolbar>

      </core.AppBar>
    </div>
  );
}
