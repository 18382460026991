/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { useHistory } from 'react-router-dom';
import { getPartitionsList } from './utils';
import Car from './Car';
import Trip from './Trip';
import Parcel from './Parcel';
import Profile from './Profile';
import Offer from './Offer';
import Verification from './Verification';
import Rating from './Rating';
import Deal from './Deal';

const NotificationsComponent: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const tabValue = queryParams.get('tab') as string || '1';
  const partitions = getPartitionsList(t);

  const handleChange = (event: any, newValue: string) => {
    history.push(`?tab=${newValue}`)
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '100%', margin: '30px' }}>
          <TabContext value={tabValue}>
            <Tabs value={tabValue} onChange={handleChange} textColor='primary' indicatorColor='primary'>
              {partitions.map(partition => <Tab value={`${partition.id}`} label={partition.name} />)}
            </Tabs>
            <TabPanel value='1'>
              <Parcel />
            </TabPanel>
            <TabPanel value='2'>
              <Trip />
            </TabPanel>
            <TabPanel value='3'>
              <Profile />
            </TabPanel>
            <TabPanel value='4'>
              <Car />
            </TabPanel>
            <TabPanel value='5'>
              <Verification />
            </TabPanel>
            <TabPanel value='6'>
              <Offer />
            </TabPanel>
            <TabPanel value='7'>
              <Rating />
            </TabPanel>
            <TabPanel value='8'>
              <Deal />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NotificationsComponent;
