/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, FC, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { GET_COLUMN, OPTIONS } from './utils';
import { PayoutClient, PlaceDetails } from '../../services/api';
import createParcelsListFromRowData from '../../models/modelFactory/parcel';
import { AppContextType, useApp } from '../../context/AppContext';
import useQuery from '../../hooks/useQuery';
import { IconButton } from '@material-ui/core';
import { Payment } from '@material-ui/icons';

const PayoutComponent: FC = () => {
  const { t } = useTranslation();
  const TITLE = t("payouts")
  const [data, setData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRaws, setTotalRaws] = useState<number>(0);
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const { getPayouts, verifyPayout } = PayoutClient();
  const { getPlaceDetails } = PlaceDetails();
  const { baggages } = useApp() as AppContextType;

  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get('page') as string) || 1;
  const pageSize = parseInt(queryParams.get('pageSize') as string) || 10;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://yookassa.ru/payouts-data/3.1.0/widget.js';
    script.async = true;

    script.onload = () => {
      setScriptLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const parcels = await getPayouts();
      const parsedData = parcels ? createParcelsListFromRowData(parcels, baggages,t) : [];
      const fromData = await Promise.all(parsedData.map(parcel => parcel.fromPlaceId && getPlaceDetails(parcel.fromPlaceId)));
      const toData = await Promise.all(parsedData.map(parcel => parcel.toPlaceId && getPlaceDetails(parcel.toPlaceId)));
      const localizedData = parsedData.map(parcel => {
        const from = fromData.find(item => item?.placeId === parcel.fromPlaceId);
        const to = toData.find(item => item?.placeId === parcel.toPlaceId);
        return {
          ...parcel,
          addressFrom: from?.name ?? parcel.addressFrom,
          addressTo: to?.name ?? parcel.addressTo,
        }
      });
      setTotalRaws(1);
      setTotalPages(1);
      setData(localizedData);
    })();
  }, [baggages, t, scriptLoaded]);

  const onChangePage = (currentPage: number) => {
    history.push(`?page=${currentPage + 1}&pageSize=${pageSize}`);
  };

  const queryObject: any = query.getObject();
  const column = useMemo(() => GET_COLUMN(queryObject,t), []);

  const payout = {
    name: 'id',
    label: t('payout'),
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <IconButton
            onClick={async () => {
              if (scriptLoaded) {
                const payoutsData = new (window as any).PayoutsData({
                  type: 'payout',
                  account_id: '308674',
                  success_callback: async (data) => {
                    const body = {
                      parcelId: value,
                      payoutToken: data.payout_token
                    };
                    const res = await verifyPayout(body);
                    if(typeof(res) == 'string') window.location.reload();
                      else alert('Something went wrong');
                  },
                  error_callback: (error) => {
                    console.log(error);
                  }
                });
                payoutsData.render('root').then(() => {});
              }
            }}>
            <Payment />
          </IconButton>
        );
      }
    }
  }

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={TITLE}
            data={data}
            columns={[...column, payout]}
            options={{
              ...OPTIONS(t),
              setRowProps: (row) => {
                return {
                  onDoubleClick: () => {
                    history.push(`/parcel/details/${row[2]}`);
                  }
                };
              },
              serverSide: true,
              onChangeRowsPerPage: (numberOfRows) => {
                history.push(`?page=1&pageSize=${numberOfRows}`);
              },
              rowsPerPage: pageSize,
              page: page - 1,
              onChangePage,
              pagination: totalPages > 1,
              rowsPerPageOptions: [5, 10, 25, 50, 100],
              count: totalRaws
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PayoutComponent;
