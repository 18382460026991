import NotificationPartitionModel from "models/notificationPartition";

export const GET_COLUMN = (): any => [
  {
    name: 'id',
    options: {
      filter: false,
      sort: false,
      display: false,
      viewColumns: false,
    }
  },
  {
    name: 'message',
    options: {
      filter: false,
      sort: false,
      customHeadRender: () => null,
      customBodyRender: (value: string, tableMeta: any) => {
        return (
          <div style={{ cursor: 'pointer', fontWeight: !tableMeta.rowData[3] ? 'bold' : 'unset' }}>
            {value}
          </div>
        );
      }
    }
  },
  {
    name: 'action',
    options: {
      filter: false,
      sort: false,
      display: false
    }
  },
  {
    name: 'read',
    options: {
      filter: false,
      sort: false,
      display: false
    }
  },
  {
    name: 'createdOnTS',
    options: {
      filter: false,
      sort: true,
      display: false
    }
  },
  {
    name: 'lastModifiedTS',
    options: {
      filter: false,
      sort: true,
      display: false
    }
  }
]

export const OPTIONS: any = () => {
  return {
    print: false,
    download: false,
    filter: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: true
  }
}

export const getPartitionsList = (t: any): Array<NotificationPartitionModel> => {
  return [
    {id: 1, name: t('parcels')},
    {id: 2, name: t('trips')},
    {id: 3, name: t('profiles')},
    {id: 4, name: t('cars')},
    {id: 5, name: t('verification')},
    {id: 6, name: t('offers')},
    {id: 7, name: t('reviews')},
    {id: 8, name: t('deals')},
  ];
}
