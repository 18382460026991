/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {  GET_COLUMN_USER, OPTIONS, getVerificationStatusCode, setRowBackColor } from './utils';
import { Verification, UserClient } from '../../services/api';
import useQuery from '../../hooks/useQuery';
import createVerificationUserFromRowData from 'models/modelFactory/verification-user';
import { getVerificationStatus } from 'models/util';
import { IconButton } from '@material-ui/core';
import { VerifiedUser, VerifiedUserOutlined } from '@material-ui/icons';

const User = (): JSX.Element => {
  const { t } = useTranslation();
  const TITLE = t("verification_requests");
  const [data, setData] = useState<any>([]);
  const [totalRaws, setTotalRaws] = useState<number>(0);
  const [statusCode, setStatusCode] = useState<number>();
  const { getVerificationUser } = Verification();
  const { getUserById } = UserClient();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get('page') as string) || 1;
  const pageSize = parseInt(queryParams.get('pageSize') as string) || 10;
  const tab = parseInt(queryParams.get('tab') as string) || 1;


  useEffect(() => {
    (async () => {
      const userVerifications = await getVerificationUser();
      const updatedData = await Promise.all(userVerifications.map(async (obj: any) => {
        const user = await getUserById(obj.userId);
        return {
          ...obj,
          verificationStatus: t(getVerificationStatus(obj.verificationStatus)),
          userFullName: `${user.firstName ?? ''} ${user.lastName ?? ''} ${user.middleName ?? ''}`
        }
      }));
      setData(createVerificationUserFromRowData(updatedData));
      setTotalRaws(0);
    })();
  }, [page, pageSize, statusCode]);

  const onChangePage = (currentPage: number) => {
    history.push(`?tab=${tab}&page=${currentPage + 1}&pageSize=${pageSize}`);
  };

  const queryObject: any = query.getObject();

  const isReviewedColumn = {
    name: 'isReviewed',
    label: t('is_reviewed'),
    options: {
      filter: false,
      sort: false,
      customFilterListOptions: {
        render: (v: boolean) => {
          if (v) return t('is_reviewed');
          return
        },
      },
      filterType:"checkbox" as any,
      filterOptions: {
        names: [true],
        logic(active, filterVal, row) {
          return !row[0];
        },
        renderValue: (val: boolean) => {
          if (val === true) {
            return t('yes');
          }
        }
      },
      customBodyRender: (value: boolean) => (
        <IconButton>
          {value ? <VerifiedUser /> : <VerifiedUserOutlined />}{' '}
        </IconButton>
      ),

    }
  };
  const column = useMemo(() => GET_COLUMN_USER(queryObject, t), []);

  return (
    <React.Fragment>
      <MUIDataTable
        title={TITLE}
        data={data}
        columns={[isReviewedColumn, ...column]}
        options={{
          ...OPTIONS(t),
          onFilterChange(changedColumn: string, filterList, type, index) {
            if (changedColumn) {
              switch (changedColumn) {
                case 'verificationStatus':
                  query.delete('verificationStatus');
                  if (filterList[index][0]) {
                    query.set('verificationStatus', filterList[index][0]);
                    setStatusCode(getVerificationStatusCode(filterList[index][0], t));
                  } else {
                    setStatusCode(undefined);
                  }
                  break;
                default:
                  query.delete(changedColumn);
                  filterList[index][0] ?? query.set(changedColumn, filterList[index][0]);
                  break;
              }
            }
            if (type === 'reset') {
              query.delete('verificationStatus');
              setStatusCode(undefined);
            }
            history.push(`?${query.toString()}`);
          },
          setRowProps: (row) => {
            return {
              onDoubleClick: () => {
                history.push(`/verificationRequests/user/details/${row[1]}`);
              },
              style: {
                background:
                  setRowBackColor(row[3], t)
              }
            };
          },
          onChangeRowsPerPage: (numberOfRows) => {
            history.push(`?tab=${tab}&page=1&pageSize=${numberOfRows}`)
          },
          serverSide: true,
          rowsPerPage: pageSize,
          page: page - 1,
          onChangePage,
          pagination: totalRaws > 5,
          rowsPerPageOptions: [5, 10, 25, 50, 100],
          count: totalRaws
        }}
      />
    </React.Fragment>
  );
};

export default User;
