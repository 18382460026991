/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import MUIDataTable from "mui-datatables";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DealApi, UserClient } from "services/api";
import useQuery from "hooks/useQuery";
import createDealsFromRowData from "models/modelFactory/deal";
import { GET_COLUMN, OPTIONS } from "./utils"

const DealComponent: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const TITLE = t("deals");
  const { getDeals } = DealApi();
  const { getUserById } = UserClient();
  const [data, setData] = useState<any>([]);
  const query = useQuery();

  useEffect(() => {
    (async () => {
      const deals = await getDeals();
      const updatedData = await Promise.all(deals.map(async deal => {
        const user = await getUserById(deal.creatorId);
        return {
          ...deal,
          fio: `${user?.firstName ?? ''} ${user?.lastName ?? ''} ${user?.middleName ?? ''}`.trim(),
        }
      }));
      setData(createDealsFromRowData(updatedData));
    })();
  }, []);
  
  const queryObject: any = query.getObject();
  const column = useMemo(() => GET_COLUMN(queryObject, t), []);

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={TITLE}
            data={data}
            columns={[...column]}
            options={OPTIONS(t)}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default DealComponent;
