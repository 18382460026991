import moment from 'moment';
import { getDeviceType } from '../UserComponent/utils'
import { Select, MenuItem, ListItemText, TextField } from '@material-ui/core';
import { Rating } from '@mui/material';
import { getVerificationStatus } from 'models/util';
import { UserClient } from 'services/api';
import Hundred from '../../assets/images/hundred.svg';
import Ten from '../../assets/images/ten.svg';
import Fifty from '../../assets/images/fifty.svg';
import Thirty from '../../assets/images/thirty.svg';
import Russia from '../../assets/images/russia.png';
import English from '../../assets/images/united_kingdom.png';
import Armenia from '../../assets/images/armenia.png';
import Turkey from '../../assets/images/turkey.png';
import RUB from '../../assets/images/rub.png';
import AED from '../../assets/images/aed.png';
import USD from '../../assets/images/usd.png';
import EUR from '../../assets/images/eur.png';

const { changeUserDetail } = UserClient();

enum ratingTypes {
  shifter = 'ratingAsShifter',
  sender = 'ratingAsSender'
};

const renderCountImage = (count: number) => {
  switch (true) {
    case count < 10:
      return <img src={Thirty} alt='something' height={'50'} width={"50"} style={{ marginLeft: 10 }} />
    case count >= 10 && count < 50:
      return <img src={Ten} alt='something' height={'50'} width={"50"} style={{ marginLeft: 10 }} />
    case count >= 50 && count < 100:
      return <img src={Fifty} alt='something' height={'50'} width={"50"} style={{ marginLeft: 10 }} />
    case count >= 100:
      return <img src={Hundred} alt='something' height={'50'} width={"50"} style={{ marginLeft: 10 }} />
    default:
      <></>
  }
}

const renderWithIcon = (type: string, value: string, t: any) => {
  const langOptions = [
    {
        title: t("russian"),
        icon: Russia,
        short_name: "ru"
    },
    {
        title: t("english"),
        icon: English,
        short_name: "en"
    },
    {
        title: t("armenian"),
        icon: Armenia,
        short_name: "hy"
    },
    {
        title: t("turkish"),
        icon: Turkey,
        short_name: "tr"
    },
  ];

  const currencyOptions = [
    {
        title: t("RUB"),
        icon: RUB,
        short_name: "rub"
    },
    {
        title: t("USD"),
        icon: USD,
        short_name: "usd"
    },
    {
        title: t("EUR"),
        icon: EUR,
        short_name: "eur"
    },
    {
        title: t("AED"),
        icon: AED,
        short_name: "aed"
    },
];
  const options = type === 'lang' ? langOptions : currencyOptions;
  const current = options.find((elem: any) => elem.short_name === value);
  return current && (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p>{current.title ?? t('unknown')}</p>
      <img src={current.icon} alt='langIcon' height={'20'} width={"20"} style={{ marginLeft: 10 }}/>
    </div>
  )
}

export const createUserDetail = (detail: {
  ratingAsShifter: any;
  ratingAsSender: any;
  phone: any;
  lastName: any;
  firstName: any;
  middleName: any;
  birthdayTS: moment.MomentInput;
  email: any;
  createdOnTS: moment.MomentInput;
  accountType: any,
  confirmed_nomer: any,
  lastActiveTS: number,
  successDealsCount: number,
  successParcelsCount: number,
  lastKnownLocation: any,
  isInBlackList: boolean,
  verificationStatus: any,
  deviceType: string,
  lastModifiedTS: moment.MomentInput,
  currency: string,
  language: string
}, t: any) => {
  return [
    {
      name: t('first_name_user'),
      data: detail.firstName,
      isChanged: false
    },
    {
      name: t("second_name"),
      data: detail.lastName,
      isChanged: false
    },
    {
      name: t('third_name'),
      data: detail.middleName,
      isChanged: false
    },
    {
      name: t("phone_number"),
      data: detail.phone,
      isChanged: false
    },
    {
      name: t('birth_day'),
      data: moment(detail.birthdayTS).format('DD.MM.YYYY').toString(),
      isChanged: false
    },
    {
      name: t("email"),
      data: detail.email,
      isChanged: false
    },
    {
      name: t('created_on'),
      data: moment(detail.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
      isChanged: false
    },
    {
      name: t("role"),
      data: t(detail.accountType.toLowerCase()),
      isChanged: false
    },
    {
      name: t("confirmed_nomer"),
      data: ".",
      isChanged: false
    },
    {
      name: t("email_confirmed"),
      data: ".",
      isChanged: false
    },
    {
      name: t("last_geoData"),
      data: detail.lastKnownLocation,
      isChanged: false
    },
    {
      name: t("time_refresh_geoData"),
      data: moment(detail.lastModifiedTS).format('DD.MM.YYYY HH:mm').toString(),
      isChanged: false
    },
    {
      name: t("devType"),
      data: getDeviceType(detail.deviceType),
      isChanged: false
    },
    {
      name: t("number_of_trips"),
      data: detail.successDealsCount ?? 0,
      isChanged: false
    },
    {
      name: t("number_of_parcels"),
      data: detail.successParcelsCount ?? 0,
      isChanged: false
    },
    {
      name: t("last_activation"),
      data: moment(detail.lastActiveTS).format('DD.MM.YYYY HH:mm').toString(),
      isChanged: false
    },
    {
      name: t("rating_shifter"),
      data: detail.ratingAsShifter,
      isChanged: false
    },
    {
      name: t("rating_sender"),
      data: detail.ratingAsSender,
      isChanged: false
    },
    {
      name: t("black_list"),
      data: detail.isInBlackList ?? false,
      isChanged: false
    },
    {
      name: t("verificationStatus"),
      data: detail.verificationStatus,
      isChanged: false
    },
    {
      name: t("language"),
      data: detail.language.toLowerCase(),
      isChanged: false
    },
  ];
};

export const createFilesOfUser = (detail: {
  name: any;
  patronymic: any;
  surname: any;
  militaryNumber: any;
  verificationStatus: any;
}) => {
  return [
    {
      name: 'Имя',
      data: detail.name
    },
    {
      name: 'Отчество',
      data: detail.patronymic
    },
    {
      name: 'Фамилия',
      data: detail.surname
    },
    {
      name: 'militaryNumber',
      data: detail.militaryNumber
    },
    {
      name: 'Статус проверки',
      data: detail.verificationStatus
    }
  ];
};

export const OPTIONS: any = (t: any) => {
  return {
    filter: false,
    responsive: 'vertical',
    draggableColumns: {
      enabled: true
    },
    print: false,
    download: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: false,
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t("sort"),
        columnHeaderTooltip: (column: any) => `${t("sort_by")} ${column.label}`,
      },
      pagination: {
        next: t("next_page"),
        previous: t("previous"),
        rowsPerPage: t("rowsPerPage"),
        displayRows: t("a_from"),
      },
      toolbar: {
        search: t("search"),
        downloadCsv: t("downloadCsv"),
        print: t("print"),
        viewColumns: t("viewColumn"),
        filterTable: t("filterTable"),
      },
      filter: {
        all: t("all"),
        title: t('filters'),
        reset: t("reset"),
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t("show_hide_table_columns"),
      },
      selectedRows: {
        text: t("row_selected"),
        delete: t("delete"),
        deleteAria: t("delete_area"),
      },
    },
  };
}

export const createReviewsFromData = (data: any) => {
  return data?.map((review: any) => {
    return {
      description: review.description,
      rate: review.rate,
      imageUrl: review.imageUrl,
      createdOn: moment(review.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
      id:review.id,
      userType:review.userType,
      fromUserId:review.fromUserId,
      toUserId:review.toUserId,
      parcelOrTripId:review.parcelId??review.tripId,
      hasParcel:review?.parcelId?true:false
    }
  })
}

export const getAccountType = (value: any, t: any): string => {
  switch (value) {
    case t('shifter'): return 'Shifter'
    case t('sender'): return 'Sender'
    default: return 'Admin'
  }
}

export const COLUMN_ONE = (optionValues: any, setUserType: any, setValueByRowIndex: any, t: any) => [
  {
    name: 'name',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  },
  {
    name: 'data',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      ),
      customBodyRender: (
        value: any,
        tableMeta: any,
        updateValue: (arg0: any) => void
      ): JSX.Element => (
        <>
          {tableMeta.rowIndex !== 6 &&
            (tableMeta.tableData[tableMeta.rowIndex][2] ||
            tableMeta.tableData[tableMeta.rowIndex].isChanged) ? (
            tableMeta.rowIndex === 7 ? (
              <Select
              value={value}
              onChange={(event) => {
                setUserType(getAccountType(event.target.value, t));
                updateValue(event.target.value);
              }}>
              {optionValues.map((item: string, index: number) => (
                <MenuItem key={index} value={item}>
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
              </Select>
            ) : (
              <TextField
                value={value}
                type={
                  tableMeta.rowIndex === 4 ? 'date' : tableMeta.rowIndex === 5 ? 'email' : 'text'
                }
                onChange={(event: any) => {
                  const valueTextField: any = event.nativeEvent.target.value;
                  const index: number = tableMeta.rowIndex;

                  setValueByRowIndex(index, valueTextField);
                  updateValue(event.nativeEvent.target.value);
                }}
                InputProps={{ inputProps: { max: '2022-01-01' } }}
              />
            )
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {(tableMeta.rowIndex === 10) &&
                <a href={`https://www.google.com/maps/search/?api=1&query=${value?.lat ?? 40.78659},${value?.long ?? 43.83845}`} target="_blank" rel="noreferrer">
                  {t("open_in_the_map")}
                </a>
              }
              {<p>{value}</p>}
            </div>
          )}
        </>
      )
    }
  },
  {
    name: 'isChanged',
    label: 'id',
    options: {
      display: false,
      viewColumns: false
    }
  }
];

export const COLUMN_TWO = (id: number, setUserType: any, setValueByRowIndex: any, t: any) => [
  {
    name: 'name',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  },
  {
    name: 'data',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      ),
      customBodyRender: (
        value: any,
        tableMeta: any,
        updateValue: (arg0: any) => void
      ): JSX.Element => (
        <>
          {
            (tableMeta.rowIndex === 2 || tableMeta.rowIndex === 3) &&
            (tableMeta.tableData[tableMeta.rowIndex][2] ||
            tableMeta.tableData[tableMeta.rowIndex].isChanged) ?
            (
              <TextField
                value={value}
                type={'number'}
                onChange={(event: any) => {
                  const valueTextField: any = event.nativeEvent.target.value;
                  const index: number = tableMeta.rowIndex;

                  setValueByRowIndex(index, valueTextField);
                  updateValue(valueTextField);
                }}
              />
            ) :
            (
              <div style={{ display: "flex", alignItems: "center" }}>
                {(tableMeta.rowIndex === 5) &&
                  <Rating
                    name={ratingTypes.shifter}
                    value={value}
                    precision={1}
                    onChange={async (evt, newValue) => {
                      await changeUserDetail(id, {[ratingTypes.shifter]: newValue ?? 0});
                      updateValue(newValue);
                    }}
                  />
                }
                {(tableMeta.rowIndex === 6) &&
                  <Rating
                    name={ratingTypes.sender}
                    value={value}
                    precision={1}
                    onChange={async (evt, newValue) => {
                      await changeUserDetail(id, {[ratingTypes.sender]: newValue ?? 0});
                      updateValue(newValue);
                    }}
                  />
                }
                {<>
                  <p style={{ marginLeft: "10px" }}>
                    {
                      tableMeta.rowIndex === 7 ? (value ? t('yes') : t('no')) :
                      tableMeta.rowIndex === 8 ? t(getVerificationStatus(value)) :
                      tableMeta.rowIndex === 9 ? renderWithIcon('lang', value, t) :
                      tableMeta.rowIndex === 10 ? renderWithIcon('currency', value, t) :
                      value
                    }
                  </p>
                  {(tableMeta.rowIndex === 2 || tableMeta.rowIndex === 3) && renderCountImage(value)}
                </>}
              </div>
            )
          }
        </>
      )
    }
  },
  {
    name: 'isChanged',
    label: 'id',
    options: {
      display: false,
      viewColumns: false
    }
  }
];
