import moment from 'moment';

export const COLUMN = (t: any, details: any) => [
  {
    name: 'name',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  },
  {
    name: 'data',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      ),
      customBodyRender: (
        value: any,
        tableMeta: any,
        updateValue: (arg0: any) => void
      ): JSX.Element => (
        <>
          {
            tableMeta.rowIndex === 2
            ? <div style={{height:"30px",width:"30px",borderRadius:'15px',backgroundColor:`#${value}`,border: `0.5px solid black`}} />
            : tableMeta.rowIndex === 4
            ? <p style={{cursor: 'pointer'}} onClick={() => {window.open(`/user/details/${details.clientId}`, '_blank') }}>{value}</p>
            : <p>{value}</p>
          }
        </>
      )
    }
  }
];
export const labels = [
  'Телефонный номер',
  'Фамилия',
  'Имя пользователя',
  'Отчество',
  'Дата рождения',
  'Эл. адрес',
  'Дата регистрации'
];

export const createCarDetail = (
  detail: any,
  t: any
): { name: string; data: any }[] => {
  return [
    {
      name: t('brand'),
      data: detail.brand
    },
    {
      name: t('model'),
      data: detail.model
    },
    {
      name: t('color'),
      data: detail.color
    },
    {
      name: t('ID'),
      data: detail.id
    },
    {
      name: t('fio_owner'),
      data: detail.fio,
    },
    {
      name: t('date_of_creation'),
      data: moment(detail.createdOnTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('verificationStatus'),
      data: detail.verificationStatus
    },

  ];
};



export const OPTIONS: any = (t: any) => {
  return {
    filter: false,
    responsive: 'vertical',
    draggableColumns: {
      enabled: true
    },
    print: false,
    download: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: false,
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};
export const COMLUMNOFFERS = (t?: any) => [
  {
    name: 'fullUserName',
    label: t('fio')
  },
  {
    name: 'description',
    label: t('description')
  },
  {
    name: 'offerStatus',
    label: t('offer_status')
  },
  {
    name: 'offerSum',
    label: t('sum')
  },
  {
    name: 'payment',
    label: t('payment')
  },
  {
    name: 'phoneNumber',
    label: t('number_phone')
  }
];
export const createOffers = (offers: any, t: any) => {
  return offers.map((offer) => {
    return {
      fullUserName: offer?.clientInfoModel?.fullUserName,
      description: offer.description,
      offerStatus: offer.offerStatus,
      offerSum: offer.offerSum,
      payment:
        offer?.paymentStatus &&
        offer?.paymentStatus.includes('Paid')
        ? t('done')
        : t('not_done'),
      phoneNumber: offer.phoneNumber
    };
  });
};
