/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useMemo, useContext, useEffect } from 'react';
import { ParcelClient } from '../services/api';

export type BaggageType = {
  name: string;
  synonym: string;
  value: number;
};

export type AppContextType = {
  baggages: BaggageType[];
  isAuthenticated: Boolean;
};

export type AppActionContextType = {
  setBaggagesToState: (newBaggages: BaggageType[]) => void;
  setIsAuthenticated: (isAuthenticated: Boolean) => void;
};

export const AppContext = createContext<AppContextType>({
  baggages: [],
  isAuthenticated: false
});

export const AppActionContext = createContext<AppActionContextType>({
  setBaggagesToState: () => {},
  setIsAuthenticated: () => {}
});

export const AppProvider = ({ children }): JSX.Element => {
  const { getParcelTypes } = ParcelClient();
  const [baggages, setBaggagesToState] = useState<BaggageType[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const baggages = await getParcelTypes();
        setBaggagesToState(baggages);
        setIsAuthenticated(!!localStorage.getItem('accessToken') || false);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(true);
      }
    })();
  }, [setIsAuthenticated]);

  const providerValue = useMemo(
    () => ({
      baggages,
      isAuthenticated
    }),
    [baggages, isAuthenticated]
  );

  const actionValue = useMemo(
    () => ({
      setBaggagesToState,
      setIsAuthenticated
    }),
    [setBaggagesToState, setIsAuthenticated]
  );

  return (
    <AppActionContext.Provider value={actionValue}>
      <AppContext.Provider value={providerValue}>{isLoading && children}</AppContext.Provider>
    </AppActionContext.Provider>
  );
};

export const useApp = (): AppContextType => useContext(AppContext);
export const useActionApp = (): AppActionContextType => useContext(AppActionContext);

export default { useApp, AppProvider };
