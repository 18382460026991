/* eslint-disable import/no-extraneous-dependencies */
import moment from 'moment';
import VerificationCarModel from "models/verificationCar";


type verificationCarResponseModel = {
  carId: string,
  imgs:Array<string>,
  carPassportPhotos: Array<string>,
  comment: string,
  isReviewed: boolean,
  verificationStatus: string,
  id:string,
  createdOn: string,
  createdOnTS: number,
  brand:string,
  model:string,
  year:string
};

const createCarfromRowData = (responseItem: verificationCarResponseModel): any => {
  return new VerificationCarModel({
    carId: responseItem?.carId,
    verificationStatus: responseItem?.verificationStatus,
    id: responseItem.id,
    brand:responseItem.brand,
    model:responseItem.model,
    year:responseItem.year,
    isReviewed:responseItem.isReviewed,
    createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
  });
};

const createVerificationCarFromRowData = (
  response: Array<verificationCarResponseModel>
): Array<VerificationCarModel> =>
  response.map((item: verificationCarResponseModel) => createCarfromRowData(item));

export default createVerificationCarFromRowData;
