/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import { TabContext, TabPanel } from '@material-ui/lab';
import { ListItemText, MenuItem, Select, Tab, Tabs } from '@material-ui/core';
import { COLUMN, createParcelDetail, OPTIONS, statuses, COLUMN_SHIFTER, COMLUMNOFFERS, createOffers, getStatus } from './utils';
import { ParcelClient, RatingApi, TripClient, PlaceDetails } from '../../services/api';
import { AppContextType, useApp } from '../../context/AppContext';
import { ShifterImages } from './ShifterImages';
import ReviewComponent from 'components/UserDetail/components/reviews/Reviews';
import { createReviewsFromData } from 'components/UserDetail/utils';

const ParcelDetail = function (): JSX.Element {
  const { t } = useTranslation();
  const { changeRating,deleteRating } = RatingApi();

  const title = t('parcel');
  const { id } = useParams();
  const { getParcelById, changeDeliveryStatus, getOffersByParcelId, getReviewsByParcelId } = ParcelClient();
  const { getTripById } = TripClient();
  const { getPlaceDetails } = PlaceDetails();
  const { baggages } = useApp() as AppContextType;
  const [data, setData] = useState<any[]>([]);
  const [dataShifter, setDataShifter] = useState<any[]>([]);
  const [value, setValue] = React.useState('1');
  const [shifterUploadedImages, setShifterUploadedImages] = useState([]);
  const [senderUploadedImages,setSenderUploadedImages] = useState([]);
  const [deliveryStatus,setDeliveryStatus]=useState<any>('');
  const [change, setChange] = useState(false);
  const [offers, setOffers] = useState([]);
  const [reviews, setReviews] = useState([]);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };
  const COLUMN_TWO = [
    {
      name: 'name',
      label: '',
      options: {
        customHeadRender: ({index}) => (
          <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
        )
      }
    },
    {
      name: 'data',
      label: '',
      options: {
        customHeadRender: ({index}) => (
          <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
        ),
        customBodyRender: (
          value: any,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ): JSX.Element => (
          <>
            {tableMeta.rowIndex === 4 &&
            (tableMeta.tableData[tableMeta.rowIndex][2] ||
              tableMeta.tableData[tableMeta.rowIndex].isChanged) ? (
               (            
                <Select
                value={value}
                onChange={(event) => {   
                  setDeliveryStatus(event.target.value);          
                  updateValue(event.target.value);
                }}>
                {statuses(t).map((item, index) => (
                  <MenuItem key={index} value={item.title}>
                    <ListItemText primary={item.title} />
                  </MenuItem>
                ))}
              </Select>
              )
            ) : (
              <p>{value}</p>
            )}
          </>
        )
      }
    },
    {
      name: 'isChanged',
      label: 'id',
      options: {
        display: false,
        viewColumns: false
      }
    }
  ];
  useEffect(() => {
    (async () => {
      const detailParcel = await getParcelById(id);
      const locationFrom = await getPlaceDetails(detailParcel.fromPlaceId ?? 'locationFrom');
      const locationTo = await getPlaceDetails(detailParcel.toPlaceId ?? 'locationTo');
      const allOffers = await getOffersByParcelId(id) ?? [];
      setOffers(createOffers(allOffers, t));
      Promise.all([locationFrom, locationTo]).then(async (res) => {
        detailParcel.addressFrom = res[0].fullAddress;
        detailParcel.addressTo = res[1].fullAddress;
        const tripById = allOffers.length && await getTripById(detailParcel.tripId);
        const shifter = tripById ? [tripById] : [];
        const parcelDetail = createParcelDetail(detailParcel, baggages, t);
        detailParcel?.receiver && parcelDetail.push({
          name: t('number_phone_recipient'),
          data: detailParcel?.receiver?.phone
        })
        detailParcel?.receiver && parcelDetail.push({
          name: t('fio_recipient'),
          data: `${detailParcel?.receiver?.name ?? ''} ${detailParcel?.receiver?.surname}`
        });
        parcelDetail.push({
          name: t('payment'),
          data:
            detailParcel?.paymentStatus && detailParcel?.paymentStatus.includes('Paid')
              ? t('done')
              : t('not_done')
        });
        const reviews = await getReviewsByParcelId(detailParcel.id) ?? [];
        setReviews(createReviewsFromData(reviews.ratings) ?? [])
        setData(parcelDetail);
        setDataShifter(shifter ?? []);
        setShifterUploadedImages(detailParcel?.shifterUploadedImages ?? []);
        setDeliveryStatus(detailParcel.deliveryStatus);
        setSenderUploadedImages(detailParcel?.clientUploadedImages ?? [])
      });
    })();
  }, [baggages, t]);
  const changeData = (value: boolean) => {
    const array = [...data];
    for (let index = 0; index < array.length; index++) {
      array[index].isChanged = value;
    }
    setData(array);
  };
  const changeReview = async (data) => {
    await changeRating(data);
    const reviews = await getReviewsByParcelId(id);
    setReviews(createReviewsFromData(reviews.ratings))
  }
  const deleteReview = async (id) => {
    await deleteRating(id);
    const reviews = await getReviewsByParcelId(id);
    setReviews(createReviewsFromData(reviews.ratings))
  }
  const saveChangeUserDetail = async () => {
    const detail = await changeDeliveryStatus(id, {deliveryStatus: getStatus(deliveryStatus, t)});
    const locationFrom = getPlaceDetails(detail.locationFromPlaceId);
    const locationTo = getPlaceDetails(detail.locationToPlaceId);
    Promise.all([locationFrom, locationTo]).then((res) => {
      detail.addressFrom = res[0].fullAddress;
      detail.addressTo = res[1].fullAddress;
      const parcelDetail = createParcelDetail(detail, baggages, t);
      detail?.receiverInfo && parcelDetail.push({
        name:t('number_phone_recipient'),
        data:detail?.receiverInfo?.phoneNumber
      })
      detail?.receiverInfo && parcelDetail.push({
        name:t('fio_recipient'),
        data:detail?.receiverInfo?.fullName
      });
      parcelDetail.push({
        name: t('payment'),
        data:
        detail?.paymentStatus && detail?.paymentStatus.includes('Paid')
            ? t('done')
            : t('not_done')
      });
      setData(parcelDetail);
    });
    window.location.reload();
  };
  const dataTableTwo = useMemo(
    () => (
      <MUIDataTable
            title={''}
            data={data.slice(8, data.length)}
            columns={COLUMN_TWO}
            options={OPTIONS(t)}
          />
    ),
    [data,]
  );
  return (
    <React.Fragment>
      <h1 style={{ textAlign: 'center' }}>{title}</h1>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ width: '40%', marginRight: '2%' }}>
          <MUIDataTable
            title={''}
            data={data.slice(0, 8)}
            columns={COLUMN(t)}
            options={OPTIONS(t)}
          />
        </div>
        <div style={{ width: '40%', marginLeft: '2%' }}>
         {dataTableTwo}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={() => {setChange(!change); changeData(!change)}}>
              {change?t('cancel'): t('change')}
            </Button>
          </div>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={saveChangeUserDetail}>
              {t('save')}
            </Button>
          </div>
  
        </div>
      <div style={{ width: '100%', margin: '30px' }}>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange} textColor='primary' indicatorColor='primary'>
          <Tab value='1' label={`${t('orders')} (${offers.length})`} />
            <Tab value='2' label={`${t('reviews')} (${reviews.length})`} />
            <Tab value='3' label={`${t('photo_from_the_carrier')} (${shifterUploadedImages.length})`} />
            <Tab value='4' label={`${t('photo_from_the_sender')} (${senderUploadedImages.length})`} />
            <Tab value='5' label={`${t('info_shifter')} (${dataShifter.length})`} />
          </Tabs>
          <TabPanel value='1'>
            <MUIDataTable
              title={''}
              data={offers}
              columns={COMLUMNOFFERS(t)}
              options={OPTIONS(t)}
            />
          </TabPanel>
          <TabPanel value='2'>
            <ReviewComponent
              data={reviews}
              changeReview={changeReview}
              deleteReview={deleteReview}
            />
          </TabPanel>
          <TabPanel value='3'>
            <ShifterImages list={shifterUploadedImages} />
          </TabPanel>
          <TabPanel value='4'>
            <ShifterImages list={senderUploadedImages} />
          </TabPanel>
          <TabPanel value='5'>
            <div style={{ width: '80%' }}>
              <MUIDataTable
                title={''}
                data={dataShifter}
                columns={COLUMN_SHIFTER(t)}
                options={{
                  ...OPTIONS(t),
                }}
              />
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </React.Fragment>
  );
};

export default ParcelDetail;
