import Dialog from '@mui/material/Dialog';
import './accordion.css'
import { Button, IconButton, TextField } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'constants/language';

const DialogComponent = function (props: { 
  handleClose: () => void,
  open: boolean,
  reason: string,
  setReason: (value: string) => void,
  add: () => void,
  title: string,
  language?: string
}): JSX.Element {
  const { t } = useTranslation();
  const renderLanguage = (value: string) => {
    const current = LANGUAGES.find((elem: any) => elem.short_name === value);
    return current && (
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontWeight: 'bold' }}>{t('language') + ':'}</p>
        <p style={{ marginLeft: 10 }}>{t(current.title) ?? t('unknown')}</p>
        <img src={current.icon} alt='langIcon' height={'20'} width={"20"} style={{ marginLeft: 5 }}/>
      </div>
    )
  }

  return (
    <Dialog onClose={props.handleClose} open={props.open} >
      <div style={{
        backgroundColor: "white",
        minHeight: '150px',
        maxHeight: '1000px',
        minWidth: '500px',
        overflow: 'auto', borderRadius: 5, padding: '10px', boxShadow: "1px 15px 8px #9E9E9E"
      }}>
        <div style={{ width: '100%', display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
          <h3 style={{ marginLeft: 5 }}>{props.title}</h3>
          <p>{renderLanguage(props.language?.toLowerCase() ?? 'ru')}</p>
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
            style={{ right: 5 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <TextField
            multiline
            minRows={4}
            color='secondary'
            value={props.reason}
            onChange={(event) => { props.setReason(event.target.value) }}
            style={{ paddingBottom: 15, width: '100%' }}
            variant="outlined"
          />
        </div>
        <Button
          variant='outlined'
          onClick={props.add}
        >
          {t('add')}
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogComponent;
