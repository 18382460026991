/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useTranslation } from "react-i18next";

function Alert(props: AlertProps):JSX.Element {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DefaultNotice(props: { err?: any; err_text?: any; }):JSX.Element {
  const { t } = useTranslation();
  const { err, err_text } = props;
  const [open, setOpen] = React.useState(true);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={`verticalhorizontal`}>
        <Alert onClose={handleClose} severity={err ? "error" : "success"}>
          {err ? err_text : t("action_completed_successfully") + '!'}
        </Alert>
      </Snackbar>
    </div>
  );
}
