/* eslint-disable @typescript-eslint/naming-convention */
export enum APP_ROUTE {
  ROOT = '/',
  USER = '/user',
  LOGIN = '/login',
  MAIN = '/main',
  PARCEL = '/parcel',
  TRIP = '/trip',
  CHART = '/chart',
  REPORT = '/report',
  POPULAR_CITIES = '/popularCities',
  USER_DETAILS = '/user/details/:id',
  TRIP_DETAILS = '/trip/details/:id',
  PARCEL_DETAILS = '/parcel/details/:id',
  ADD_POPULAR_CITY = '/addPopularCity',
  FEEDBACK = '/reviews',
  CAR = '/cars',
  CAR_DETAILS = '/cars/details/:id',
  BLACK_LIST = '/blackList',
  VERIFICATION_REQUESTS = '/verificationRequests',
  DETAIL_BLACK_LIST = '/blackList/details/:id',
  DETAIL_VERIFICATION_USER = '/verificationRequests/user/details/:id',
  DETAIL_VERIFICATION_CAR = '/verificationRequests/car/details/:id',
  DETAIL_VERIFICATION_TRIP = '/verificationRequests/trip/details/:id',
  PAYOUT = '/parcel/payout',
  MAP = '/map',
  DEAL = '/deal',
  NOTIFICATIONS = '/notifications'
}
