export const GET_COLUMN = (filtersFromUrl: any, t): any => [
  {
    name: 'fio',
    label: t('fio_offer'),
    options: {
      filter: false,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.brand ? [filtersFromUrl.brand] : []
    },
  },
  {
    name: 'price',
    label: t('sum'),
    options: {
      filter: false,
      sort: false,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.model ? [filtersFromUrl.model] : []
    },
  },
  {
    name: 'status',
    label: t('offer_status'),
    options: {
      filter: false,
      sort: false,
    },
  }
]

export const OPTIONS: any = (t: any) => {
  return {
    print: false,
    download: false,
    filter: true,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
}
