class ParcelModel {
  readonly parcelTitle: string;
  readonly createdOn: string;
  readonly date: string;
  readonly id: string;
  readonly addressFrom: string;
  readonly addressTo: string;
  readonly fio: string;
  readonly phoneNumber: string;
  readonly baggageTypes: Array<string>;
  readonly parcelSum: number;
  readonly dealType:number;
  readonly isActual:boolean;
  readonly fromPlaceId:string;
  readonly toPlaceId:string;
  readonly deliveryStatus:string;
  
  constructor({
    parcelTitle,
    createdOn,
    date,
    id,
    addressFrom,
    addressTo,
    fio,
    phoneNumber,
    baggageTypes,
    parcelSum,
    dealType,
    isActual,
    fromPlaceId,
    toPlaceId,
    deliveryStatus
  }) {
    this.parcelTitle = parcelTitle;
    this.createdOn = createdOn;
    this.date = date;
    this.id = id;
    this.addressFrom = addressFrom;
    this.addressTo = addressTo;
    this.fio = fio;
    this.phoneNumber = phoneNumber;
    this.baggageTypes = baggageTypes;
    this.parcelSum = parcelSum;
    this.dealType=dealType;
    this.isActual=isActual;
    this.fromPlaceId=fromPlaceId
    this.toPlaceId=toPlaceId
    this.deliveryStatus=deliveryStatus
  }
}

export default ParcelModel
