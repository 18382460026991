/* eslint-disable @typescript-eslint/naming-convention */
import english from 'assets/images/united_kingdom.png'
import russia from 'assets/images/russia.png'
import turkey from 'assets/images/turkey.png'
import pakistan from 'assets/images/armenia.png'
import arabic from 'assets/images/armenia.png'
import india from 'assets/images/armenia.png'
import china from 'assets/images/armenia.png'
import france from 'assets/images/armenia.png'
import germany from 'assets/images/armenia.png'
import spain from 'assets/images/armenia.png'
import portugal from 'assets/images/armenia.png'

export const LANGUAGES = [
  {
    title: 'english',
    icon: english,
    short_name: 'en'
  },
  {
    title: 'russian',
    icon: russia,
    short_name: 'ru'
  },
  {
    title: 'turkish',
    icon: turkey,
    short_name: 'tr'
  },
  {
    title: 'urdu',
    icon: pakistan,
    short_name: 'ur'
  },
  {
    title: 'arabic',
    icon: arabic,
    short_name: 'ar'
  },
  {
    title: 'hindi',
    icon: india,
    short_name: 'hi'
  },
  {
    title: 'chinese',
    icon: china,
    short_name: 'zh'
  },
  {
    title: 'french',
    icon: france,
    short_name: 'fr'
  },
  {
    title: 'german',
    icon: germany,
    short_name: 'de'
  },
  {
    title: 'spanish',
    icon: spain,
    short_name: 'es'
  },
  {
    title: 'portuguese',
    icon: portugal,
    short_name: 'pt'
  },
]
