import { useState } from 'react';
import { useTranslation } from "react-i18next";
import MUIDataTable from 'mui-datatables';
import useQuery from 'hooks/useQuery';
import { OPTIONS, getRateByValue } from './utlis';
import moment from 'moment';
import { Button, FormGroup, FormLabel, TextField } from '@material-ui/core';
import ModalImage from 'react-modal-image';
import { Delete, Edit } from '@material-ui/icons';
import { Add } from '@mui/icons-material';
import { Rating } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { setRowBackColor } from 'models/util';

const ReviewComponent = (props: { data: Array<any>, changeReview: (data) => void, deleteReview: (id: number) => void, paginationData?: any, filterData?: any }): JSX.Element => {
  const data: Array<any> = props.data;

  const { t } = useTranslation();
  const query = useQuery();
  const [idRating, setIdRating] = useState("")
  const history = useHistory();
  const check = (description: string, rate: number) => {
    const changingData = { ...data.find((element) => element.id === idRating) };
    if (description || rate) {
      if(description){
        changingData.description = description
      }
      changingData.rate = getRateByValue(rate);
      changingData.ratingId = changingData.id;
      return changingData
    }
    else return
  }
  const navigateParcelOrTrip = (id, value) => {
    const review = { ...data.find((element) => element.id === id) };
    review.hasParcel ?
      window.open(`/parcel/details/${value}`, '_blank') :
      window.open(`/trip/details/${value}`, '_blank');


  }
  const GET_COLUMN = (filtersFromUrl: any,) => [
    {
      name: 'description',
      label: t('description'),
      options: {
        filter: true,
        sort: true,
        filterType: 'textField' as any,
        filterList: filtersFromUrl.description ? [filtersFromUrl.description] : [],
        customBodyRender: (
          value: any,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ): JSX.Element => (
          <>{tableMeta.rowData[7] === idRating ?
            (
              <TextField
                value={value}
                type={
                  'text'
                }
                onChange={(event: any) => {
                  updateValue(event.nativeEvent.target.value);

                }}
              />
            )
            :
            <p>{value}</p>}
          </>
        )
      }
    },
    {
      name: 'rate',
      label: t('rating'),
      options: {
        filterList: filtersFromUrl.rate ? [filtersFromUrl.rate] : [],
        filterOptions: {
          names: ['1', '2', '3', '4', '5']
        },
        customBodyRender: (
          value: any,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ): JSX.Element => (
          <>
            <Rating
              readOnly={!(tableMeta.rowData[7] === idRating)}
              value={value}
              precision={1}
              onChange={(val,newValue) => {updateValue(newValue)}}
            />
          </>
        )
      }
    },
    {
      name: 'createdOn',
      label: t("date_of_creation"),
      options: {
        filter: true,
        sort: true,
        filterType: 'custom' as any,
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [`${t("from")}: ${v[0]}`, `${t("to")}: ${v[1]}`];
            } else if (v[0]) {
              return `${t("from")}: ${v[0]}`;
            } else if (v[1]) {
              return `${t("to")}: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterList: (() => {
          const list: any = [];
          if (filtersFromUrl.createdOnStart) {
            list[0] = filtersFromUrl.createdOnStart;
          }
          if (filtersFromUrl.createdOnEnd) {
            list[1] = filtersFromUrl.createdOnEnd;
          }
          return list;
        })(),
        //sortDirection: 'desc',
        sortCompare: (order: string) => {
          return (obj1: { data: moment.MomentInput; }, obj2: { data: moment.MomentInput; }) => {
            const val1 = moment(obj1.data, "DD.MM.YYYY HH:mm");
            const val2 = moment(obj2.data, "DD.MM.YYYY HH:mm");
            return val1.diff(val2) * (order === 'asc' ? -1 : 1);
          };
        },
        filterOptions: {
          names: [],
          logic(date: moment.MomentInput, filters: any[]): boolean {
            const check: any = moment(date, "DD.MM.YYYY HH:mm");
            const from: any = moment(filters[0], 'YYYY-MM-DD');
            const to: any = moment(filters[1], 'YYYY-MM-DD');
            if (
              filters[0] &&
              filters[1] &&
              check.diff(to, 'days') > 0 &&
              check.diff(from, 'days') < 0
            ) {
              return true;
            } else if (filters[1] && check.diff(to, 'days') > 0) {
              return true;
            } else if (filters[0] && check.diff(from, 'days') < 0) {
              return true;
            }
            return false;
          },
          display: (filterList: { [x: string]: any; }, onChange: (arg0: any, arg1: any, arg2: any) => void, index: string | number, column: any): JSX.Element => (
            <div>
              <FormLabel>{t("date_of_creation")}</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label={t("start_date")}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '48%', marginRight: '4%', marginTop: '3%' }}
                />
                <TextField
                  id="endDate"
                  label={t("end_date")}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '48%', marginTop: '3%' }}
                />
              </FormGroup>
            </div>
          )
        }
      }
    },
    {
      name: 'imageUrl',
      label: t('image'),
      options: {
        customBodyRender: (value, tableMeta,) => {
          return (
            <ModalImage
              small={value}
              large={value}
              alt={`Image`}
              className={'modalImage'}
              hideDownload={true}
              hideZoom={true}
            />
          )
        },
        filter: false
      }
    },
    {
      name: 'userFrom',
      label: t('from_whom'),
      options: {
        filter: true,
        sort: false,
        filterType: 'textField' as any,
        filterList: filtersFromUrl.fromUser ? [filtersFromUrl.fromUser] : [],
        customBodyRender: (
          value: any,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ): JSX.Element => (
          <>
            <p onClick={() => {
              window.open(`/user/details/${tableMeta.rowData[8]}`, '_blank');
            }}>{value}</p>
          </>
        )
      }
    },
    {
      name: 'userTo',
      label: t('to_whom'),
      options: {
        filter: true,
        sort: false,
        filterType: 'textField' as any,
        filterList: filtersFromUrl.toUserId ? [filtersFromUrl.toUserId] : [],
        customBodyRender: (
          value: any,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ): JSX.Element => (
          <>
            <p onClick={() => {
              window.open(`/user/details/${tableMeta.rowData[8]}`, '_blank');
            }}>{value}</p>
          </>
        )
      }
    },
    {
      name: 'parcelOrTripId',
      label: t('parcelOrTripId'),
      options: {
        filter: false,
        sort: true,
        filterType: 'textField' as any,
        filterList: filtersFromUrl.parcelOrTripId ? [filtersFromUrl.parcelOrTripId] : [],
        customBodyRender: (
          value: any,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ): JSX.Element => (
          <>
            <p onClick={() => { navigateParcelOrTrip(tableMeta.rowData[7], value) }}>{value}</p>
          </>
        )
      }
    },
    {
      name: 'id',
      label: t('change'),
      options: {
        customBodyRender: (value, tableMeta,) => {
          return (
            value === idRating ?
              <Button onClick={() => {
                setIdRating("");
                check(tableMeta.rowData[0] !== tableMeta.tableData[0][0] ? tableMeta.rowData[0] : "",tableMeta.rowData[1]!== tableMeta.tableData[0][1] ? tableMeta.rowData[1] : null) 
                && props.changeReview(check(tableMeta.rowData[0] !== tableMeta.tableData[0][0] ? tableMeta.rowData[0] : "",tableMeta.rowData[1]!== tableMeta.tableData[0][1] ? tableMeta.rowData[1] : null))
              }}>
                <Add />
              </Button>
              :
              <Button onClick={() => { setIdRating(value) }}>
                <Edit />
              </Button>)
        },
        filter: false
      }
    },
    {
      name: 'id',
      label: t('delete'),
      options: {
        customBodyRender: (value: any) => {
          return (
            <Button onClick={() => {
              const valuePrompt = window.confirm(`${t('delete')}?`);
              valuePrompt && props.deleteReview(value);
            }}>
              <Delete />
            </Button>)
        },
        filter: false
      }
    }

  ];

  const onChangePage = (currentPage: number) => {
    history.push(`?page=${currentPage + 1}&pageSize=${props.paginationData.pageSize}`);
  };

  const queryObject: any = query.getObject();

  return (
    <div style={{ width: '90%' }}>
      <MUIDataTable
        title={''}
        data={data}
        columns={GET_COLUMN(queryObject)}
        options={{
          ...OPTIONS(t),
          onFilterChange: (changedColumn: string, filterList, type, index, data) => {
            switch (changedColumn) {
              case 'description': {
                query.delete('description');
                if (filterList[index][0]) {
                  query.set('description', filterList[index][0]);
                  props.filterData.setDescription(filterList[index][0])
                } else {
                  query.delete('description');
                  props.filterData.setDescription(undefined);
                }
                break;
              }
              case 'rate': {
                query.delete('rate');
                if (filterList[index][0]) {
                  query.set('rate', filterList[index][0]);
                  props.filterData.setRate(parseInt(filterList[index][0]));
                } else {
                  query.delete('rate');
                  props.filterData.setRate(undefined);
                }
                break;
              }
              case 'createdOn': {
                query.delete('createdOnStart');
                query.delete('createdOnEnd');

                if (filterList[index][0]) {
                  query.set('createdOnStart', filterList[index][0]);
                  props.filterData.setCreatedOnFrom(moment(filterList[index][0]).valueOf());
                } else {
                  query.delete('createdOnStart');
                  props.filterData.setCreatedOnFrom(undefined);
                }

                if (filterList[index][1]) {
                  query.set('createdOnEnd', filterList[index][1]);
                  props.filterData.setCreatedOnTo(moment(filterList[index][1]).valueOf());
                } else {
                  query.delete('createdOnEnd');
                  props.filterData.setCreatedOnTo(undefined);
                }
                break;
              }
              case 'userFrom': {
                query.delete('fromUser');
                if (filterList[index][0]) {
                  query.set('fromUser', filterList[index][0]);
                  props.filterData.setFromUser(filterList[index][0]);
                } else {
                  query.delete('fromUser');
                  props.filterData.setFromUser(undefined);
                }
                break;
              }
              case 'userTo': {
                query.delete('toUser');
                if (filterList[index][0]) {
                  query.set('toUser', filterList[index][0]);
                  props.filterData.setToUser(filterList[index][0]);
                } else {
                  query.delete('toUser');
                  props.filterData.setToUser(undefined);
                }
                break;
              }
              default: {
                if (changedColumn) {
                  query.delete(changedColumn);
                  if (filterList[index][0]) {
                    query.set(changedColumn, filterList[index][0]);
                  }
                } else if (type === 'reset') {
                  query.delete('description');
                  query.delete('rate');
                  query.delete('createdOnStart');
                  query.delete('createdOnEnd');
                  query.delete('fromUser');
                  query.delete('toUser');
                  props.filterData.setDescription(undefined);
                  props.filterData.setRate(undefined);
                  props.filterData.setCreatedOnFrom(undefined);
                  props.filterData.setCreatedOnTo(undefined);
                  props.filterData.setFromUser(undefined);
                  props.filterData.setToUser(undefined);
                }
                break;
              }
            }
          },
          setRowProps: (row) => ({ style: { background: setRowBackColor(row[2]) }}),
          serverSide: true,
          onChangeRowsPerPage: (numberOfRows) => {
            history.push(`?page=1&pageSize=${numberOfRows}`);
          },
          rowsPerPage: props.paginationData?.pageSize,
          page: props.paginationData?.page - 1,
          onChangePage,
          pagination: props.paginationData?.totalRaws > 5,
          rowsPerPageOptions: [5, 10, 25, 50, 100],
          count: props.paginationData?.totalRaws
        }}
      />
    </div>
  );
};

export default ReviewComponent;
