/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, FC, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import * as style from '@material-ui/core/styles';
import { OPTIONS, GET_COLUMN } from './utils';
import { PopularCities, PlaceDetails } from '../../services/api';
import useQuery from '../../hooks/useQuery';
import createPopularCitiesFromRowData from 'models/modelFactory/popular-city';
import { ajaxAction } from 'services';

const useStyles = style.makeStyles((theme: style.Theme) =>
  style.createStyles({
    input: {
      display: 'none'
    },
    button: {}
  })
);

const PopularCitiesComponent: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const { getPopularCitiesAll, deletePopularCityById, changeImage } = PopularCities();
  const { getPlaceDetails } = PlaceDetails();
  const history = useHistory();
  const query = useQuery();
  const handleUploadClick = async (event: React.ChangeEvent<HTMLInputElement>,tableMeta:any) => {
    const files = event.target.files;
    if (files) {
      const file: any = files[0];
      if (file) {
        const url = process.env.REACT_APP_HOST_HOST_API + 'image/upload';
        const xForm = new FormData();
        xForm.append('Image', file);
        const method = 'POST';
        const resp: any = await ajaxAction(url, method, xForm);
        await changeImage({id: tableMeta.rowData[3], imageUrl: resp.data.imageUrl});
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    (async () => {
      const popularCityAll = await getPopularCitiesAll();
      const parsedData = popularCityAll ? createPopularCitiesFromRowData(popularCityAll) : [];
      const locationData = await Promise.all(parsedData.map(city => getPlaceDetails(city.city)));
      const localizedData = parsedData.map(city => {
        const place = locationData.find(item => item.placeId === city.city)
        return {
          ...city,
          city: place.fullAddress,
        }
      })
      setData(localizedData);
    })();
  }, []);

  const onChangePage = (currentPage: number) => {
    query.set('page', (currentPage + 1).toString());
    window.history.pushState(null, '', `?${query.toString()}`);
  };

  const queryObject: any = query.getObject();

  const checkPageSize = (object) => {
    return object?.pageSize;
  };

  const column = useMemo(() => GET_COLUMN(queryObject, t, classes, handleUploadClick, deletePopularCityById), []);
  const pageSize = useMemo(() => checkPageSize(queryObject), []);

  const AddButton = () => (
    <Tooltip disableFocusListener title='Add City'>
      <IconButton
        onClick={() => {
          history.push(`addPopularCity`);
        }}>
        <AddCircle />
      </IconButton>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={t('popular_cities')}
            data={data}
            columns={column}
            options={{
              customToolbar: AddButton,

              ...OPTIONS(t),
              onChangeRowsPerPage: (numberOfRows) => {
                query.set('pageSize', numberOfRows.toString());
                window.history.pushState(null, '', `?${query.toString()}`);
              },
              rowsPerPage: pageSize || 10,
              onFilterChange(changedColumn: string, filterList, type, changedColumnIndex) {
                switch (changedColumn) {
                  case 'createdOn': {
                    query.delete('createdOnStart');
                    query.delete('createdOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('createdOnStart', filterList[changedColumnIndex][0]);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('createdOnEnd', filterList[changedColumnIndex][1]);
                    }
                    break;
                  }

                  default:
                    if (changedColumn) {
                      query.delete(changedColumn);
                      if (filterList[changedColumnIndex][0]) {
                        query.set(changedColumn, filterList[changedColumnIndex][0]);
                      }
                    }
                    break;
                }
                window.history.pushState(null, '', `?${query.toString()}`);
              },
              page: parseInt(queryObject.page) || 0,
              onChangePage,
              pagination: data.length > 10
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PopularCitiesComponent;
