import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as style from '@material-ui/core/styles';
import Autocomplete from 'react-google-autocomplete';
import { Input, Fab, Button } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { AddPhotoAlternate, Delete } from '@material-ui/icons';
import { PopularCities } from 'services/api';
import DefaultNotice from '../NoticeComponent';
import { ajaxAction } from 'services';

const useStyles = style.makeStyles((theme: style.Theme) =>
  style.createStyles({
    container: {
      margin: 30
    },
    input: {
      display: 'none'
    },
    button: {
      color: blue[900],
      marginBottom: 10
    },
    image: {
      height: '100px',
      width: '100px'
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end'
    }
  })
);

const AddPopularCity: FC = () => {
  const { t } = useTranslation();
  const { createPopularCity } = PopularCities();
  const [imageURL, setImageURL] = useState('');
  const [place, setPlace] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const language = localStorage.getItem('language') ?? 'ru';
  const [imageFile, setImageFile] = useState(null);
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
  };
  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file: any = files[0];
      if (file) {
        setImageFile(file);
        getBase64(file, (result) => {
          setImageURL(result);
        });
      }
    }
  };
  const addPopularCity = async () => {
    if (imageFile) {
      const url = process.env.REACT_APP_HOST_HOST_API + 'image/upload';
      const xForm = new FormData();
      xForm.append('Image', imageFile);
      const method = 'POST';
      const resp: any = await ajaxAction(url, method, xForm);
      createPopularCity({
        placeId: place.place_id,
        imageUrl: resp.data.imageUrl
      });
      setOpen(true);
      return 
    }
    createPopularCity({
      placeId: place.place_id,
      imageUrl: ''
    });
    setOpen(true);
  };

  return (
    <div className={classes.container}>
      <h1>{t('add_popular_city')}</h1>
      <div>
        {imageURL ? (
          <div className={classes.imageContainer}>
            <img className={classes.image} src={imageURL} alt='City' />
            <Delete
              color='primary'
              onClick={() => {
                setImageURL('');
              }}
            />
          </div>
        ) : (
          <>
            <input
              accept='image/*'
              className={classes.input}
              id='contained-button-file'
              multiple
              type='file'
              onChange={handleUploadClick}
            />
            <label htmlFor='contained-button-file'>
              <Fab component='span' className={classes.button}>
                <AddPhotoAlternate />
              </Fab>
            </label>
          </>
        )}
      </div>
      <div>
        <Input
          fullWidth
          color='secondary'
          style={{ width: '30%', marginTop: '30px', marginBottom: '30px' }}
          inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
            <Autocomplete
              apiKey={'AIzaSyCL9JBbWUVp27zGmZOUR6cErzH43tXOhGQ'}
              {...props}
              language={language}
              defaultValue={place ? place.name : ''}
              onPlaceSelected={(selected) => {
                setPlace(selected);
              }}
              options={{ fields: ['place_id', 'name'] }}
            />
          )}
        />
      </div>
      {open && <DefaultNotice />}
      <Button
        color='primary'
        variant='contained'
        onClick={addPopularCity}
        disabled={!place?.place_id}>
        {t('add')}
      </Button>
    </div>
  );
};

export default AddPopularCity;
