/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useTranslation } from "react-i18next";
import { TabContext, TabPanel } from '@material-ui/lab';
import { Tab, Tabs } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Trip from './Trip';
import User from './User';
import Car from './Car';

const VerificationRequests = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get('tab') as string || '1';

  const handleTabChange = (event: any, newValue: string) => {
    history.push(`?tab=${newValue}`)
  };

 
  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '100%', margin: '30px' }}>
          <TabContext value={tabValue}>
            <Tabs value={tabValue} onChange={handleTabChange} textColor='primary' indicatorColor='primary'>
              <Tab value='1' label={`${t('profile')}`} />
              <Tab value='2' label={`${t('car')}`} />
              <Tab value='3' label={`${t('trip')}`} />
            </Tabs>
            <TabPanel value='1'>
              <User />
            </TabPanel>
            <TabPanel value='2'>
              <Car />
            </TabPanel>
            <TabPanel value='3'>
              <Trip />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerificationRequests;
