/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from "react-i18next";
import {  GET_COLUMN, OPTIONS } from './utils';
import { NotificationsApi } from '../../services/api';

const Offer = (): JSX.Element => {
  const { t } = useTranslation();
  const type = 'Offer';
  const [data, setData] = useState<any>([]);
  const { getNotificationsByType, markNotificationsAsRead } = NotificationsApi();

  useEffect(() => {
    (async () => {
      const data = await getNotificationsByType(type);
      setData(data);
    })();
  }, []);

  const markAsRead = async (id: number) => {
    await markNotificationsAsRead(id);
    const data = await getNotificationsByType(type);
    setData(data);
  }

  const column = useMemo(() => GET_COLUMN(), []);

  return (
    <React.Fragment>
      <MUIDataTable
        title={''}
        data={data}
        columns={column}
        options={{...OPTIONS(),
          onRowClick: (row) => {
            markAsRead(parseInt(row[0]));
          }
        }}
      />
    </React.Fragment>
  );
};

export default Offer;
