class UserModel {
  readonly phone: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly middleName: string;
  readonly createdOn: string;
  readonly birthday: string;
  readonly email: string;
  readonly id: string;
  readonly role: string;
  readonly deviceType: string;

  constructor({ phone, firstName, lastName, middleName, createdOn, birthday, email, id, role, deviceType }) {
    this.phone = phone;
    this.firstName = firstName;
    this.lastName = lastName;
    this.middleName = middleName;
    this.createdOn = createdOn;
    this.birthday = birthday;
    this.email = email;
    this.id = id;
    this.role = role;
    this.deviceType = deviceType;
  }
}

export default UserModel;
