enum CREDENTIALS {
  ACCESS_TOKEN = 'accessToken',
  EXPIRES_IN = 'expires_in',
  TOKEN_TYPE = 'token_type',
  SCOPE = 'scope',
  REFRESH_TOKEN = 'refreshToken',
  ERROR_TEXT = 'errorText'
}

export const saveCredentials = (credentials: CREDENTIALS):any => {
  localStorage.setItem(CREDENTIALS.ACCESS_TOKEN, credentials[CREDENTIALS.ACCESS_TOKEN]);
  localStorage.setItem(CREDENTIALS.EXPIRES_IN, credentials[CREDENTIALS.EXPIRES_IN]);
  localStorage.setItem(CREDENTIALS.TOKEN_TYPE, credentials[CREDENTIALS.TOKEN_TYPE]);
  localStorage.setItem(CREDENTIALS.REFRESH_TOKEN, credentials[CREDENTIALS.REFRESH_TOKEN]);
  localStorage.setItem(CREDENTIALS.SCOPE, credentials[CREDENTIALS.SCOPE]);
};

export const clearCredentials = ():any => {
  localStorage.removeItem(CREDENTIALS.ACCESS_TOKEN);
  localStorage.removeItem(CREDENTIALS.EXPIRES_IN);
  localStorage.removeItem(CREDENTIALS.TOKEN_TYPE);
  localStorage.removeItem(CREDENTIALS.REFRESH_TOKEN);
  localStorage.removeItem(CREDENTIALS.SCOPE);
};

export default saveCredentials;
