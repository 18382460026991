/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import MUIDataTable from 'mui-datatables';
import { GET_COLUMN, OPTIONS, getUserTypeByCode, getDeviceType } from './utils';
import { UserClient } from '../../services/api';
import { setRowBackColor } from 'models/util';
import { createSortedUserListFromRowData } from '../../models/modelFactory/user';
import useQuery from '../../hooks/useQuery';
import moment from 'moment';

const UserComponent = (): JSX.Element => {
  const {t}=useTranslation();
  const TITLE=t("users")
  const [data, setData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRaws, setTotalRaws] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [userType, setUserType] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [secondName, setSecondName] = useState<string>();
  const [thirdName, setThirdName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [dateOfBirthFrom, setDateOfBirthFrom] = useState<number>();
  const [dateOfBirthTo, setDateOfBirthTo] = useState<number>();
  const [createdOnFrom, setCreatedOnFrom] = useState<number>();
  const [createdOnTo, setCreatedOnTo] = useState<number>();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get('page') as string) || 1;
  const pageSize = parseInt(queryParams.get('pageSize') as string) || 10;

  const { getUserAll, getPaginatedUsers } = UserClient();

  useEffect(() => {
    (async () => {
      if (!query.has('page') && !query.has('pageSize')) history.push(`?page=${page}&pageSize=${pageSize}`);
      const { items, total, totalPages } = await getPaginatedUsers(page, pageSize, {
        phoneNumber, userType, firstName, secondName, thirdName, email, dateOfBirthFrom, dateOfBirthTo, createdOnFrom, createdOnTo
      });
      const allUsers = await getUserAll();
      const parsedData = items ? createSortedUserListFromRowData(items) : createSortedUserListFromRowData(allUsers.items);
      const updatedData = parsedData.map((user:any) => {
        const role = user.role ? t(user.role.toLowerCase()) : '';
        const devType = user.deviceType ? getDeviceType(user.deviceType) : '';
        return {
          ...user,
          role: role,
          deviceType: devType
        }
      });
      setTotalRaws(total);
      setTotalPages(totalPages);
      setData(updatedData);
    })();
  }, [page, pageSize, phoneNumber, userType, firstName, secondName, thirdName, email, dateOfBirthFrom, dateOfBirthTo, createdOnFrom, createdOnTo]);

  const onChangePage = (currentPage: number) => {
    history.push(`?page=${currentPage + 1}&pageSize=${pageSize}` +
    `${userType ? '&userType=' + t(getUserTypeByCode(userType)) : ''}` +
    `${firstName ? '&firstName=' + firstName : ''}`
  )};

  const queryObject: any = query.getObject();
  const column = useMemo(() => GET_COLUMN(queryObject,t), []);

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={TITLE}
            data={data}
            columns={column}
            options={{
              ...OPTIONS(t),
              onFilterChange(changedColumn: string, filterList, type, changedColumnIndex) {
                switch (changedColumn) {
                  case 'userName':
                    query.delete('phoneNumber');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('phoneNumber', filterList[changedColumnIndex][0])
                      setPhoneNumber(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('phoneNumber');
                      setPhoneNumber(undefined);
                    }
                  break;
                  case 'role': {
                    query.delete('userType');
                    if (filterList[changedColumnIndex][0]) {
                      switch (filterList[changedColumnIndex][0]) {
                        case t('shiftersender'): {
                          setUserType('0');
                          break;
                        }
                        case t('shifter'): {
                          setUserType('1');
                          break;
                        }
                        case t('sender'): setUserType('2');
                      }
                      query.set('userType', filterList[changedColumnIndex][0])
                    } else {
                      query.delete('usertype');
                      setUserType(undefined);
                    }
                    break;
                  }
                  case 'firstName': {
                    query.delete('firstName');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('firstName', filterList[changedColumnIndex][0])
                      setFirstName(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('firstName');
                      setFirstName(undefined);
                    }
                    break;
                  }
                  case 'secondName': {
                    query.delete('secondName');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('secondName', filterList[changedColumnIndex][0])
                      setSecondName(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('firstName');
                      setSecondName(undefined);
                    }
                    break;
                  }
                  case 'thirdName': {
                    query.delete('thirdName');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('thirdName', filterList[changedColumnIndex][0])
                      setThirdName(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('thirdName');
                      setThirdName(undefined);
                    }
                    break;
                  }
                  case 'email': {
                    query.delete('email');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('email', filterList[changedColumnIndex][0])
                      setEmail(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('email');
                      setEmail(undefined);
                    }
                    break;
                  }
                  case 'createdOn': {
                    query.delete('createdOnStart');
                    query.delete('createdOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('createdOnStart', filterList[changedColumnIndex][0]);
                      setCreatedOnFrom(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('createdOnStart');
                      setCreatedOnFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('createdOnEnd', filterList[changedColumnIndex][1]);
                      setCreatedOnTo(moment(filterList[changedColumnIndex][1]).valueOf());
                    } else {
                      query.delete('createdOnEnd');
                      setCreatedOnTo(undefined);
                    }
                    break;
                  }
                  case 'birthday': {
                    query.delete('birthdayOnStart');
                    query.delete('birthdayOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('birthdayOnStart', filterList[changedColumnIndex][0]);
                      setDateOfBirthFrom(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('birthdayOnStart');
                      setDateOfBirthFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('birthdayOnEnd', filterList[changedColumnIndex][1]);
                      setDateOfBirthTo(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('birthdayOnEnd');
                      setDateOfBirthTo(undefined);
                    }
                    break;
                  }
                  default:
                    if (changedColumn) {
                      query.delete(changedColumn);
                      if (filterList[changedColumnIndex][0]) {
                        query.set(changedColumn, filterList[changedColumnIndex][0]);
                      }
                    } else {
                      query.delete('phoneNumber');
                      query.delete('userType');
                      query.delete('firstName');
                      query.delete('secondName');
                      query.delete('thirdName');
                      query.delete('email');
                      query.delete('createdOnStart');
                      query.delete('createdOnEnd');
                      query.delete('birthdayOnStart');
                      query.delete('birthdayOnEnd');
                      setPhoneNumber(undefined);
                      setUserType(undefined);
                      setFirstName(undefined);
                      setSecondName(undefined);
                      setThirdName(undefined);
                      setEmail(undefined);
                      setCreatedOnFrom(undefined);
                      setCreatedOnTo(undefined);
                      setDateOfBirthFrom(undefined);
                      setDateOfBirthTo(undefined);
                    }
                    break;
                }
                history.push(`?${query.toString()}`);
              },
              setRowProps: (row) => ({
                onDoubleClick: () => {
                  history.push(`user/details/${row[2]}`);
                },
                style: {
                  background: setRowBackColor(row[8])
                }
              }),
              serverSide: true,
              onChangeRowsPerPage: (numberOfRows) => {
                history.push(`?page=1&pageSize=${numberOfRows}`);
              },
              rowsPerPage: pageSize,
              page: page - 1,
              onChangePage,
              pagination: totalPages > 1,
              rowsPerPageOptions: [5, 10, 25, 50, 100],
              count: totalRaws
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserComponent;
