export const OPTIONS: any = (t: any) => {
  return {
    print: false,
    download: true,
    filter: true,
    search: true,
    sort: true,
    viewColumns: false,
    selectableRows: 'none',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'excel-format.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      }
    }
  };
};

export const getRateValue = (rate: string): number => {
  switch (rate) {
    case 'One': return 1;
    case 'Two': return 2;
    case 'Three': return 3;
    case 'Four': return 4;
    case 'Five': return 5;
    default: return 0;
  }
}
