/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, FC, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { GET_COLUMN, OPTIONS, getDeliveryStatusCode } from './utils';
import { IconButton } from '@material-ui/core';
import { StarOutlineOutlined, Star } from '@material-ui/icons';
import { ParcelClient, PlaceDetails, ProfileClient } from '../../services/api';
import createParcelsListFromRowData from '../../models/modelFactory/parcel';
import { AppContextType, useApp } from '../../context/AppContext';
import useQuery from '../../hooks/useQuery';
import { setRowBackColor } from 'models/util';

const ParcelComponent: FC = () => {
  const {t}=useTranslation();
  const TITLE=t("parcels")
  const [data, setData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRaws, setTotalRaws] = useState<number>(0);

  const [isActual, setIsActual] = useState<string>();
  const [parcelSumFrom, setParcelSumFrom] = useState<string>();
  const [parcelSumTo, setParcelSumTo] = useState<string>();
  const [createdOnFrom, setCreatedOnFrom] = useState<number>();
  const [createdOnTo, setCreatedOnTo] = useState<number>();
  const [departureDateTimeFrom, setDepartureDateTimeFrom] = useState<number>();
  const [departureDateTimeTo, setDepartureDateTimeTo] = useState<number>();
  const [fromPlaceId, setFromPlaceId] = useState<string>();
  const [toPlaceId, setToPlaceId] = useState<string>();
  const [deliveryStatus, setDeliveryStatus] = useState<number>();
  const [baggageTypes, setBaggageTypes] = useState<string[]>();
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const { getPaginatedParcels,changeActualParcel } = ParcelClient();
  const { getPlaceDetails } = PlaceDetails();
  const { baggages } = useApp() as AppContextType;

  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get('page') as string) || 1;
  const pageSize = parseInt(queryParams.get('pageSize') as string) || 10;

  useEffect(() => {
    (async () => {
      const { items, total, totalPages } = await getPaginatedParcels(page, pageSize, null, {
        isActual, parcelSumFrom, parcelSumTo, createdOnFrom, createdOnTo, departureDateTimeFrom, departureDateTimeTo, fromPlaceId, toPlaceId, deliveryStatus, baggageTypes, phoneNumber
      });
      const parsedData = items ? createParcelsListFromRowData(items, baggages, t) : [];
      const fromData = await Promise.all(parsedData.map(parcel => parcel.fromPlaceId && getPlaceDetails(parcel.fromPlaceId)));
      const toData = await Promise.all(parsedData.map(parcel => parcel.toPlaceId && getPlaceDetails(parcel.toPlaceId)));
      const localizedData = parsedData.map(parcel => {
        const from = fromData.find(item => item?.placeId === parcel.fromPlaceId);
        const to = toData.find(item => item?.placeId === parcel.toPlaceId);
        return {
          ...parcel,
          addressFrom: from?.name ?? parcel.addressFrom,
          addressTo: to?.name ?? parcel.addressTo
        }
      });
      setTotalRaws(total);
      setTotalPages(totalPages);
      setData(localizedData);
    })();
  }, [baggages, t, page, pageSize, isActual, parcelSumFrom, parcelSumTo, createdOnFrom, createdOnTo, departureDateTimeFrom,
    departureDateTimeTo, fromPlaceId, toPlaceId, deliveryStatus, baggageTypes, phoneNumber
  ]);

  const onChangePage = (currentPage: number) => {
    history.push(`?page=${currentPage + 1}&pageSize=${pageSize}`);
  };

  const queryObject: any = query.getObject();

  const isActualColumn = {
    name: 'isActual',
    label: t('isActual'),
    options: {
      filter: true,
      customFilterListOptions: {
        render: (v) => {
          if (v) {
            return t('isActual');
          } 
          return false;
        },
      },
      filterType:"checkbox" as any,
      filterOptions: {
            names: [true], 
            logic(active, filterVal, row) {
              return !row[0];
            },
            renderValue: val => {
              if (val === true) {
                return t('yes');
              }
            }
          },
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <IconButton
            onClick={async () => {
              const change = await changeActualParcel({parcelId: tableMeta.rowData[4], isActual: !value});
              change && setData(data => {
                const newData = [...data];
                newData[tableMeta.rowIndex].isActual = change.isActual;
                return newData;
              });
            }}>
            {value ? <Star /> : <StarOutlineOutlined />}
          </IconButton>
        );
      }
    }
  };
  const column = useMemo(() => GET_COLUMN(queryObject,t), []);

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={TITLE}
            data={data}
            columns={[isActualColumn,...column]}
            options={{
              ...OPTIONS(t),
              onFilterChange(changedColumn: string, filterList, type, changedColumnIndex) {
                switch (changedColumn) {
                  case 'phoneNumber':
                    query.delete('phoneNumber');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('phoneNumber', filterList[changedColumnIndex][0])
                      setPhoneNumber(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('phoneNumber');
                      setPhoneNumber(undefined);
                    }
                  break;
                  case 'isActual': {
                    if (filterList[changedColumnIndex][0]) {
                      query.set('isActual', filterList[changedColumnIndex][0]);
                      setIsActual(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('isActual');
                      setIsActual(undefined);
                    }
                    break;
                  }
                  case 'parcelSum': {
                    query.delete('parcelSumMin');
                    query.delete('parcelSumMax');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('parcelSumMin', filterList[changedColumnIndex][0]);
                      setParcelSumFrom(filterList[changedColumnIndex][0]);
                    } else {
                      query.delete('parcelSumMin');
                      setParcelSumFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('parcelSumMax', filterList[changedColumnIndex][1]);
                      setParcelSumTo(filterList[changedColumnIndex][1]);
                    } else {
                      query.delete('parcelSumMax')
                      setParcelSumTo(undefined);
                    }
                    break;
                  }
                  case 'createdOn': {
                    query.delete('createdOnStart');
                    query.delete('createdOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('createdOnStart', filterList[changedColumnIndex][0]);
                      setCreatedOnFrom(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('createdOnStart');
                      setCreatedOnFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('createdOnEnd', filterList[changedColumnIndex][1]);
                      setCreatedOnTo(moment(filterList[changedColumnIndex][1]).valueOf());
                    } else {
                      query.delete('createdOnEnd');
                      setCreatedOnTo(undefined);
                    }
                    break;
                  }
                  case 'date': {
                    query.delete('dateOnStart');
                    query.delete('dateOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('dateOnStart', filterList[changedColumnIndex][0]);
                      setDepartureDateTimeFrom(moment(filterList[changedColumnIndex][0]).valueOf());
                    } else {
                      query.delete('dateOnStart');
                      setDepartureDateTimeFrom(undefined);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('dateOnEnd', filterList[changedColumnIndex][1]);
                      setDepartureDateTimeTo(moment(filterList[changedColumnIndex][1]).valueOf());
                    } else {
                      query.delete('dateOnEnd');
                      setDepartureDateTimeTo(undefined);
                    }
                    break;
                  }
                  case 'addressFrom': {
                    query.delete('addressFrom');
                    if (filterList[changedColumnIndex][0]) {
                      const obj = filterList[changedColumnIndex][0] as any;
                      query.set('addressFrom', obj.name.split(',')[0]);
                      setFromPlaceId(obj.place_id);
                    } else {
                      query.delete('addressFrom');
                      setFromPlaceId(undefined);
                    }
                    break;
                  }
                  case 'addressTo': {
                    query.delete('addressTo');
                    if (filterList[changedColumnIndex][0]) {
                      const obj = filterList[changedColumnIndex][0] as any;
                      query.set('addressTo', obj.name.split(',')[0]);
                      setToPlaceId(obj.place_id);
                    } else {
                      query.delete('addressTo');
                      setToPlaceId(undefined);
                    }
                    break;
                  }
                  case 'deliveryStatus': {
                    query.delete('deliveryStatus');
                    if (filterList[changedColumnIndex][0]) {
                      query.set('deliveryStatus', filterList[changedColumnIndex][0]);
                      setDeliveryStatus(getDeliveryStatusCode(filterList[changedColumnIndex][0], t));
                    } else {
                      query.delete('deliveryStatus');
                      setDeliveryStatus(undefined);
                    }
                    break;
                  }
                  case 'baggageTypes': {
                    query.delete('baggageTypes');
                    if (filterList[changedColumnIndex]) {}
                    break;
                  }
                  default: {
                    if (changedColumn) {
                      query.delete(changedColumn);
                      if (filterList[changedColumnIndex][0]) {
                        query.set(changedColumn, filterList[changedColumnIndex][0]);
                      }
                    } else if (type === 'reset') {
                      query.delete('isActual');
                      query.delete('parcelSumMin');
                      query.delete('parcelSumMax');
                      query.delete('createdOnStart');
                      query.delete('createdOnEnd');
                      query.delete('dateOnStart');
                      query.delete('dateOnEnd');
                      query.delete('addressFrom');
                      query.delete('addressTo');
                      query.delete('deliveryStatus');
                      query.delete('phoneNumber');
                      setIsActual(undefined);
                      setParcelSumFrom(undefined);
                      setParcelSumTo(undefined);
                      setCreatedOnFrom(undefined);
                      setCreatedOnTo(undefined);
                      setDepartureDateTimeFrom(undefined);
                      setDepartureDateTimeTo(undefined);
                      setFromPlaceId(undefined);
                      setToPlaceId(undefined);
                      setDeliveryStatus(undefined);
                      setPhoneNumber(undefined);
                    }
                    break;
                  }
                }
                history.push(`?${query.toString()}`);
              },
              setRowProps: (row) => {
                return {
                  onDoubleClick: () => {
                    history.push(`/parcel/details/${row[4]}`);
                  },
                  style: {
                    background: setRowBackColor(row[3])
                  }
                };
              },
              serverSide: true,
              onChangeRowsPerPage: (numberOfRows) => {
                history.push(`?page=1&pageSize=${numberOfRows}`);
              },
              rowsPerPage: pageSize,
              page: page - 1,
              onChangePage,
              pagination: totalPages > 1,
              rowsPerPageOptions: [5, 10, 25, 50, 100],
              count: totalRaws
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ParcelComponent;
