/* eslint-disable import/no-extraneous-dependencies */
import { AppRoute as Content } from '.';
import { BrowserRouter as Router } from 'react-router-dom';
import { SidebarComponent } from '../components/Navigation/SidebarComponent';
import { Header } from '../components/Header';

export const Main = ():JSX.Element => (
  <Router>
    <div style={{ display: 'flex' }}>
      <SidebarComponent />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          width: '100%'
        }}>
        <Header />
        <Content />
      </div>
    </div>
  </Router>
);
