import { RestClient } from '..';

export const AuthClient = ():any => {
  const AUTH_URL: any = process.env.REACT_APP_HOST_AUTH;

  const refreshAuthentication = (refreshToken) => {
    const xForm = `grant_type=refreshToken&client_id=butler_react_app&client_secret=butler_react_app_secret&refreshToken=${refreshToken}`;
    const { post } = RestClient();

    return post({ url: 'connect/token', body: xForm, baseURL: AUTH_URL });
  };

  return {
    refreshAuthentication
  };
};

export default AuthClient;
