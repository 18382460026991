/* eslint-disable import/no-extraneous-dependencies */

import DealModel from 'models/deal';

type dealResponseModel = {
  id: number;
  creatorId: number;
  parcelId: number;
  tripId: number;
  price: number;
  currency: string;
  status: string;
  parcel: any;
  trip: any;
  fio: string;
};

const createDealfromRowData = (responseItem: dealResponseModel,): any => {
    return new DealModel({
      id: responseItem?.id,
      creatorId: responseItem?.creatorId,
      parcelId: responseItem?.parcelId,
      tripId: responseItem?.tripId,
      price: `${responseItem?.price ?? '-'} ${responseItem?.price ? responseItem?.currency : ''}`,
      status: responseItem?.status,
      parcel: responseItem?.parcel,
      trip: responseItem?.trip,
      fio: responseItem?.fio,
  });
};

export const createDealsFromRowData = (
  response:Array<dealResponseModel>
): Array<DealModel> =>
  response.map((item: dealResponseModel,) => createDealfromRowData(item));

export default createDealsFromRowData;
