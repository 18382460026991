/* eslint-disable import/no-extraneous-dependencies */

import moment from 'moment';
import CarModel from 'models/car';


type carResponseModel = {
  createdOnTS: any;
  brand: string;
  model: string;
  userId:string;
  verificationStatus:string;
  id: number;
  carPhotos:Array<string>;
  fio: string;
};

const createCarfromRowData = (responseItem: carResponseModel,): any => {
    return new CarModel({
      createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
      brand: responseItem?.brand,
      model:responseItem?.model,
      userId:responseItem?.userId,
      verificationStatus:responseItem?.verificationStatus,
      id: responseItem.id,
      image:responseItem.carPhotos.length>0?responseItem.carPhotos[0]:'',
      fio: responseItem.fio,
  });
};

export const createCarsFromRowData = (
  response:Array<carResponseModel>
): Array<CarModel> =>
  response.map((item: carResponseModel,) => createCarfromRowData(item));

export default createCarsFromRowData;
