/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, {FC } from 'react';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from "react-i18next";
import { COLUMN, OPTIONS,  } from './utlis';
import { useHistory } from 'react-router-dom';

const UserParcelList: FC<any> = (props: any): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();

  const onChangePage = (currentPage: number) => {
    props.paginationData.setPage(currentPage);
  };

  return (
    <React.Fragment>
      <div>
        <MUIDataTable
          title={``}
          data={props.data}
          columns={COLUMN(t)}
          options={{
            ...OPTIONS(t),
            serverSide: true,
            page: props.paginationData.page || 0,
            rowsPerPage: props.paginationData.pageSize || 10,
            onChangeRowsPerPage: (numberOfRows) => {
              props.paginationData.setPageSize(numberOfRows);
            },
            onChangePage,
            pagination: props.paginationData.totalPages > 1,
            rowsPerPageOptions: [5, 10, 25, 50, 100],
            count: props.paginationData.total,
            setRowProps: (row) => {
              return {
                onDoubleClick: () => {
                  history.push(`/parcel/details/${row[2]}`);
                },
              };
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default UserParcelList;
