/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import { UserClient } from "services/api";

const containerStyle = {
  width: '100%',
  height: '750px'
};

const center = {
  lat: 40.7929,
  lng: 43.8465
};

const createUserMarkers = (users: any) => {
  return users.items.map((user: any, inx: number) => {
    return {
      id: inx + 1,
      fio: `${user.firstName ? user.firstName + ' ' : ''}${user.middleName ? user.middleName + ' ' : ''}${user.lastName ? user.lastName + ' ' : ''}`,
      placeName: user.placeId ?? '',
      position: {
        lat: user.lastKnownLocation?.lat ?? 0.0,
        lng: user.lastKnownLocation?.lng ?? 0.0
      }
    }
  })
}

const customMarkers = [
  {
    id: 1,
    fio: "Pavel",
    placeName: "Moscow, Russia",
    position: { lat: 55.7558, lng: 37.6173 }
  },
  {
    id: 2,
    fio: "Ivan",
    placeName: "Tula, Russia",
    position: { lat: 54.2048, lng: 37.6185 }
  },
  {
    id: 3,
    fio: "Armen",
    placeName: "Yerevan, Armenia",
    position: { lat: 40.1872, lng: 44.5152 }
  },
  {
    id: 4,
    fio: "Davit",
    placeName: "Gyumri, Armenia",
    position: { lat: 40.7929, lng: 43.8465 }
  }
];

const GoogleMapsComponent: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const { getUserAll } = UserClient();
  const TITLE = t("google_maps");
  const [markers, setMarkers] = useState<any>(customMarkers);
  const [activeMarker, setActiveMarker] = useState<number>(-1);

  useEffect(() => {
    (async () => {
      const users = await getUserAll();
      const userMarkers = createUserMarkers(users);
      // if (userMarkers) setMarkers(userMarkers);
    })();
  }, [])

  const handleActiveMarker = (markerId: number) => {
    if (markerId === activeMarker) {
      return;
    }
    setActiveMarker(markerId);
  };

  const handleOnLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <div>
      <h1>{TITLE}</h1>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={handleOnLoad}
        >
          {markers.map(({ id, fio, placeName, position }) => (
            <Marker
              key={id}
              position={position}
              onClick={() => handleActiveMarker(id)}
            >
              {activeMarker === id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(-1)}>
                  <>
                    <p>{fio}</p>
                    <p>{placeName}</p>
                  </>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
        </GoogleMap>
    </div>
  );
}

export default GoogleMapsComponent;
